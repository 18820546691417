import { useMemo } from "react";
import { forwardRef, useState } from "react";
import Cropper from "react-easy-crop";
import * as yup from "yup";
import { useField } from "formik";
import styles from "./image-field.module.css";
import { showError, showSuccess } from "../../utils/toasts";
import getCroppedImg from "./crop-image";

const DEFAULT_ASPECT_RATIO= 384/184 // 2.08696/1 = Default aspect ratio has been set as information article aspect ratio. Followed Figma aspect ratio of image.

const schema = yup
  .mixed()
  .test(
    "fileType",
    "Images only in jpeg, jpg and png formats are allowed.",
    (value) =>
      ["jpeg", "jpg", "png"].includes(value.name.split(".").pop().toLowerCase())
  )
  .test(
    "fileSize",
    "Maximum image size allowed is 1Mb.",
    (value) => value.size <= 1000000
  );

export default forwardRef(function ImageField(
  { error = "", upload, name, aspectRatio = DEFAULT_ASPECT_RATIO, width = '100%'},
  ref
) {
  const [uploading, setUploading] = useState(false);
  const [field, meta, helpers] = useField(name);
  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [edit, setEdit] = useState(false);
  const onCropComplete = (_, _croppedAreaPixels) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  };

  const url = useMemo(() => {
    if (!file) {
      return "";
    }
    return URL.createObjectURL(file);
  }, [file]);

  const onDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!files.length) {
      return;
    }

    try {
      const result = schema.validateSync(files.item(0));
      setFileError("");
      setFile(result);
    } catch (e) {
      setFileError(e.message);
    }
  };
  const inputField = <input {...field} name={name} type="hidden" ref={ref} />;

  if (!file && !field.value) {
    return (
      <div>
        {inputField}
        <div
          className={styles.dropZone}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
          onDragLeave={(e) => e.preventDefault()}
          onDrop={onDrop}
        >
          Drop an image to upload
        </div>
        <small className={styles.error}>
          {error || fileError || meta.error}
        </small>
        <div className={styles.noteContainer}>
                      <label>
                      <span className={styles.note}>Note:</span>
                        <br></br>
                        <span className={styles.span}>-After Uploading, please click on<i> Upload button otherwise image will not be saved</i> </span>
                         <br></br>
                         <i>-Image size should be less than 1Mb(jpeg/jpg/png).</i>
                        <br></br>
                        <i>-After dropping, click on upload or click on "Edit
                        to Crop" button to zoom in/out.</i>
                      </label>
                    </div>
      </div>
    );
  }

  const onUpload = async () => {
    setUploading(true);
    try {
      const cropedImage = edit
        ? await getCroppedImg(url, croppedAreaPixels)
        : file;
      const result = await upload({
        file: cropedImage,
        name: file.name,
      });

      helpers.setValue(encodeURI(result));
      showSuccess("Image has been Uploaded");
      setFile(cropedImage);
    } catch (e) {
      showError("Image Upload Failed");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <div>
        {inputField}
        <div className={styles.cropper} style={{ aspectRatio: `${aspectRatio}`, width}}>
          {!field.value && edit ? (
            <Cropper
              image={url || field.value}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          ) : (
            <img
              onError={() => {
                setFile(null);
                setFileError("Invalid image");
              }}
              className={styles.cropper}
              src={url || field.value}
              style={{aspectRatio: `${aspectRatio}`, width}}
            />
          )}
        </div>

        {!field.value && edit && (
          <div className="controls">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
        )}
      </div>

    
        <div className={styles.buttonhead} style={{width}}>
        {!field.value && (
          <button
            className={styles.button2}
            type="button"
            onClick={() => {
              setEdit((e) => !e);
            }}
          >
            {edit ? "Cancel Edit" : "Edit to Crop"}
          </button>
        )}

        {!field.value && (
          <button
            type="button"
            className={styles.button}
            //   className={uploadButtonStyle}
            onClick={() => {
              onUpload();
            }}
          >
            Upload
          </button>
        )}

        <button
          type="button"
          className={styles.button1}
          //   className={uploadButtonStyle}
          onClick={() => {
            setFile(null);
            setEdit(false);
            helpers.setValue(null);
            showSuccess("Image Removed successfully");
          }}
        >
          Delete
        </button>
      </div>

      <div className={styles.noteContainer}>
                      <label>
                      <span className={styles.note}>Note:</span>
                        <br></br>
                        <span className={styles.span}>-After Uploading, please click on<i> Upload button otherwise image will not be saved</i> </span>
                         <br></br>
                         <i>-Image size should be less than 1Mb(jpeg/jpg/png).</i>
                        <br></br>
                        <i>-After dropping, click on upload or click on "Edit
                        to Crop" button to zoom in/out.</i>
                      </label>
                    </div>
    </div>
  );
});



