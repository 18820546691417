import {connect} from "react-redux"
import IncidentsViewer from "./IncidentsViewer";
import {getIncidents} from "../../../redux/actions/incidents/incidents.action"


const mapStateToProps = (state)=>{
    return {
        incidentsData: state.incidents.incidents,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getList: (isLoadMore,next,options)=>{
            return dispatch(getIncidents(isLoadMore,next,options))
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(IncidentsViewer);

