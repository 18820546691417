import { DATE_FORMAT } from "../../utils/constants";
import styles from "./inputs.module.css";
import Datepicker from "react-datepicker";
import { forwardRef } from "react";
import IMAGES from "../../assets/images/images";
import "react-datepicker/dist/react-datepicker.css";


export const InputPrimary = ({ inputStyle = "", ...props }) => {
  return (
    <>
      <div className="inputLabel">{props.label}</div>
      <input
        className={`${inputStyle} inputControl`}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholderText}
      />
    </>
  );
};

export const InputCheckboxWithLabel = (props) => {
  return (
    <>
      <label>
        <span className="checkboxLabel">{props.label} </span>
        <input
          className="checkboxControl"
          name={props.name}
          type={props.type}
          checked={props.isChecked}
          onChange={props.onChange}
        />
      </label>
    </>
  );
};

export const FormTextInput = ({ style = "", ...rest }) => {
  return <input className={`${styles.formInput} ${style}`} {...rest} />;
};

export const FormTextArea = ({ style = "", ...rest }) => {
  return <textarea className={`${styles.textArea} ${style}`} {...rest} />;
};

export const DatePickerInput = ({
  placeholder,
  minDate,
  maxDate,
  selected,
  onChange,
}) => {
  return (
    <Datepicker
      popperContainer={() => <div className={styles.popper} />}
      placeholderText={placeholder}
      dateFormat={DATE_FORMAT}
      minDate={minDate}
      maxDate={maxDate}
      selected={selected}
      onChange={onChange}
      customInput={<DateInput />}
    />
  );
};

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={styles.dateInputButton}
    onClick={onClick}
    ref={ref}
  >
    <img className={styles.calendarIcon} src={IMAGES.calendar} />
    {value}
  </button>
));
