import { initial } from "underscore";
import { CHANGE_TAB, FETCH_NEWS, FETCH_NEWS_DETAILS } from "../../actions/actionTypes";

const initialState = {
    data: [],
    loading: false,
    next:null,
    selectedFeedItem: {},
    selectedTab: null,
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_NEWS.REQUEST:
            return {...state, loading: true}
        case FETCH_NEWS.FAILURE:
            return {...state, loading: false}
        case FETCH_NEWS.SUCCESS:
            return {
              ...state,
              totalEntries: action.payload.totalEntries,
              data:
                state.data?.some(item => item.id === action.payload.data?.[0]?.id)
                  ? action.payload.data
                  : [...state.data, ...action.payload.data],
              next: action.payload.next,
              loading: false,
            };

        case FETCH_NEWS_DETAILS.REQUEST:
                return {...state, selectedFeedItem: {}}
        case FETCH_NEWS_DETAILS.SUCCESS:
            return {...state, selectedFeedItem: action.payload}

        case CHANGE_TAB:
            return { ...state, selectedTab: action.payload, data: [] };
        default:
            return state
    }
}

export default reducer;