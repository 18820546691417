import styles from "./labels.module.css"
import {sentenceCase} from "change-case"

export const FormFieldLabel = ({text, optional})=>{
    return (
        <label className={styles.label}>{optional? `${text} (Optional)`: text}</label>
    );
}

export const FormFieldLabel2 = ({text, required})=>{
    return (
        <label className={styles.label3}>{text}{required && <span className={styles.label2}>*</span>}</label>
    );
}

export const FormFieldLabel3 = ({text, required})=>{
    return (
        <label className={styles.label3}>{text}{required && <span className={styles.label4}>*</span>}</label>
    );
}

export const FormFieldLabel5 = ({text, required})=>{
    return (
        <label className={styles.label5}>{text}{required && <span className={styles.label4}>*</span>}</label>
    );
}

export const FormFieldValue = ({text = "", optional, Component ="li"})=>{
    const value = /[0-9]+-[0-9]+/.test(text) ? text : text && sentenceCase(text)
    return (
        <Component className={styles.value}>{optional? `${text} (Optional)`: value}</Component>
    );
}

export const FormErrorLabel = ({text})=>{
    return (
        <label className={styles.error}>{text}</label>
    );
}