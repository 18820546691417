import { BASE_URL, GET, INCIDENTS, PATCH, INCIDENT_STATS } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";
import { DateTime } from "luxon";


const unixToDate = (date) => {
    return DateTime.fromSeconds(date).toISO()
}
export const fetchIncidents = async (next, archived = false, options = {}) => {
  if (options.incidentId) {
    return fetchIncident(options.incidentId).then((incident) => ({
      totalEntries: incident ? 1 : 0,
      incidents: incident ? [{...incident,createdAt: unixToDate(incident.createdAt)}] : [],
    }));
  }
  const token = await getAccessToken();
  let resource = `${BASE_URL}${INCIDENTS}`;
  const params = new URLSearchParams({
    archived,
    ...options,
  });

  if (next) {
    params.set("next", next);
  }
  resource = `${resource}?${params.toString()}`;

  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchIncident = async (incident_id) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${INCIDENTS}/${incident_id}?`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const updateIncident = async (payload, incident_id) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${INCIDENTS}/${incident_id}`;
  return fetch(resource, {
    method: PATCH,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const archiveIncident = async (id) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${INCIDENTS}/${id}/archive`;

  return fetch(resource, {
    method: PATCH,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchIncidentsStats = async (type, options = {}) => {
  const token = await getAccessToken();
  const searchParams = new URLSearchParams(options);
  searchParams.set("type", type);
  const resource = `${BASE_URL}${INCIDENT_STATS}?${searchParams.toString()}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const unarchiveIncident = async (id) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${INCIDENTS}/${id}/unarchive`;
  const payload = [{ op: "replace", path: "/archived", value: false }];
  return fetch(resource, {
    method: PATCH,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then(statusParsing)
    .then(jsonParsing);
};
