import * as echarts from "echarts";
import { useEffect, useRef } from "react";

export const Chart = ({
  data = [],
  type,
  name,
  options = {},
  doughnut = false,
}) => {
  const container = useRef();
  useEffect(() => {
    const element = container.current;
    if (!element) return;

    const chart = echarts.init(element);

    const blueShades = [
      "#100041",
      "#006BC9",
      "#5a8ecb",
      "#003f5c",
      "#1f3b5c",
      "#2f4b7c",
      "#274472",
      "#1f3b5c",
      "#3a5f7d",
      "#4a6fa5",
      "#7195d6",
    ];

    const isPie = type === "pie";
    chart.setOption({
      ...options,
      width: isPie ? undefined : 220,
      color: blueShades,
      yAxis: !isPie
        ? {
            type: "category",
            axisLabel: { show: false },
            data: data.map(({ x }) => x ?? ""),
          }
        : undefined,
      xAxis: !isPie ? {} : undefined,
      series: [
        {
          type,
          name,
          radius: doughnut ? ["40%", "70%"] : ["70%"],
          labelLine: {
            show: false,
          },
          label: {
            overflow: "truncate",
            show: !isPie,
            formatter(value) {
              return value.name;
            },
            position: "right",
          },
          data: data.map(({ x, y }) =>
            isPie
              ? { value: y, name: x }
              : y
          ),
        },
      ],
    });

    return () => {
      chart.dispose();
    };
  }, [data, doughnut, name, options, type]);

  return (
    <div ref={container} style={{ width: "350px", height: "400px" }}></div>
  );
};
