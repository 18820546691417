import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./buttons.module.css";
import { TailSpinLoader } from "../loader/Loaders";

const withSpinner = (props) => (Button) => {
  const { onClick, children, rest } = props;
  const [loading, setLoading] = useState(false);
  if (loading) {
    return <TailSpinLoader />;
  }
  return (
    <Button
      {...rest}
      onClick={() => {
        setLoading(true);
        onClick()
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }}
    >
      {children}
    </Button>
  );
};

export const ButtonPrimary = ({
  onClick,
  type,
  children,
  isDisabled,
  id = "primary-button",
  ...props
}) => {
  return (
    <button
      {...props}
      id={id}
      type={type}
      className="buttonPrimary"
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export const ButtonSecondary = ({
  onClick,
  children,
  isDisabled,
  ...props
}) => {
  return (
    <button
      {...props}
      className="buttonSecondary"
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export const ButtonDestructive = ({ onClick, children, isDisabled }) => {
  return (
    <button
      className={styles.buttonDestructive}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

/**
 * WS stands for "With Spinner"
 *
 * props:
 *  onClick
 *  children
 *  isDisabled
 */
export const ButtonDestructiveWS = (props) => {
  return withSpinner(props)(ButtonDestructive);
};

export const LinkButton = ({ title, onClick }) => {
  return (
    <div className={styles.linkButton} onClick={onClick}>
      {title}
    </div>
  );
};

export const Add = ({ path = "/", label = "Add" }) => {
  return (
    <Link className={styles.addButton} to={path}>
      <div>{`+ ${label}`}</div>
    </Link>
  );
};

/** This submit button is only meant to be used with formik forms */
export const Submit = ({
  isSubmitting,
  title,
  style = "",
  disabled = false,
}) => {
  if (isSubmitting) {
    return <TailSpinLoader />;
  }
  return (
    <button
      disabled={disabled}
      type="submit"
      className={`${styles.submit} ${style}`}
    >
      {title}
    </button>
  );
};
