import React, { useEffect } from "react";
import styles from "./usersViewer.module.css";
import { Link,useNavigate } from "react-router-dom";
import IMAGES from "../../../assets/images/images";
import CAN from '../../../casl/can';
import SectionGaurd from "../../../gaurds/SectionGaurd";
import {Action,Resource} from "../../../casl/constants";
import {DefaultContentImage as DefaultImage} from "../../../components/content/ContentImage"
import { SectionLoader} from "../../../components/loader/Loaders";
import InfiniteScroll from "react-infinite-scroll-component";
import Container from "../../../components/sidebar/ContentContainer";
import {showConfirmation, BUTTON_TYPES} from "../../../components/alert/Confirmation";
import {showError, showSuccess} from "../../../utils/toasts";
import {Chevron, Delete , Edit} from "../../../components/content/Actions";
import Stats from "../../../components/stats/stats";




const AddUser = () => {
  return (
    <Link className={styles.addUserButton} to={"/new-user"}>
      <div>+ Add User</div>
    </Link>
  );
};

const Users = (props) => {
  console.log("============================",JSON.stringify(props.userList))
  let navigate = useNavigate();
  return (
    <>
      <div>
        {props.loading ? (
          null
        ) : (
          <Stats className={styles.total} label="Total Users" 
          value={props.totalEntries}
          />
        )}
      </div>
      <div className={styles.listContainer}>
        <div className={styles.userList}>
          {props.loading && props.userList.length === 0 ? (
            <SectionLoader />
          ) : (
            props.userList.map((item, index) => {
              return (
                <div key={index}>
                  {/* value={this.props.totalEntries} */}
                  <User
                    isCurrentUser={props.currentUserEmail === item.email}
                    data={item}
                    onDelete={() => props.onDelete(item.uid, item.displayName)}
                    onEdit={() => {
                      props.onEdit(item);
                      navigate("/new-user");
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

const User = (props) => {
  return (
    <div className={styles.userContainer}>
      {/*<Icon placeholder={IMAGES.roles} />*/}
      <DetailsContainer>
        <Title displayName={props.data.displayName} />
        <Email emailID={props.data.email} />
        <RoleDetails
          group={props.data.dg}
          role={props.data.functionalRoles.join(',')}
        />
      </DetailsContainer>
      <ActionContainer>
        <Edit onClick={props.onEdit} />
        {!props.isCurrentUser ? <Delete onClick={props.onDelete} /> : null}
        {/* <Chevron /> */}
      </ActionContainer>
    </div>
  );
};
export const Image = ({image})=>{
  return (
      <img className={styles.infoIconStyle} src={image}/>
  );
}

const Icon = ({url, placeholder })=>{
  return <DefaultImage image={placeholder}/>
}
const Title = ({displayName})=> (<label className={styles.title}>{displayName}</label>)

const DetailsContainer = ({ children }) => {
  return <div className={styles.detailsContainer}>{children}</div>;
};
const ActionContainer = ({ children }) => {
  return <div className={styles.actionContainer}>{children}</div>;
};
const Email = ({ emailID }) => <div className={styles.emailID}>{emailID}</div>;

const RoleDetails = ({ group, role, isAdditionalRoles }) => {
  return (
    <>
      <div className={styles.roleDetailsContainer}>
        <div className={styles.roleDetailsField}>
          <span className={styles.roleDetailsLabel}>Group: </span>
          <span className={styles.roleDetailsValue}>{group}</span>
        </div>
        <div className={styles.roleDetailsField}>
          <span className={styles.roleDetailsLabel}>Role: </span>
          <span className={styles.roleDetailsValue}>{role}</span>
        </div>
      </div>
    </>
  );
};

const UsersViewer = (props) => {
  const { userList, loading, nextPage, profile } = props;

  useEffect(() => {
    let isLoadMore=false;
    let _nextPage=null
    props.getUserList(isLoadMore,_nextPage);
  }, []);
  
  const fetchData=()=>{
    if (nextPage) {
        let isLoadMore=true;
        props.getUserList(isLoadMore,nextPage);
    }
  }

  const deleteUser=(id,displayName)=>{
    const buttons = [
        {
            label: "Delete",
            type: BUTTON_TYPES.Destructive,
            onClick: ()=> {
                return new Promise((res, rej)=>{
                  
                    props.deleteUser(id)
                    .then((success)=>{
                        res(true)
                        if(success){
                            showSuccess("User deleted successfully");
                            let isLoadMore=false;
                            let _nextPage=null
                            props.getUserList(isLoadMore,_nextPage);
                        }else{
                            showError("Something went wrong while deleting the user")
                        }
                    })
                })
            }
        },
        {
            label: 'Cancel',
            type: BUTTON_TYPES.Cancel,
            onClick: () => {
                return Promise.resolve(true)
            }
        }
    ]
    const title = "Confirm to delete"
    const message = `Are you sure you want to delete ${displayName} user account?`

    return showConfirmation(title, message , buttons)
}

const editUser=(user)=>{
    props.edit(user);
}

  return (
    <div className={styles.container}>
        <SectionGaurd canAccess={CAN(Action.CREATE, Resource.USERS)}>
          <AddUser />
        </SectionGaurd> 
        <SectionGaurd canAccess={CAN(Action.LIST, Resource.USERS)}>
            <div className={styles.infiniteScrollContainer}>
                <InfiniteScroll
                dataLength={userList.length} //This is important field to render the next data
                next={fetchData}
                hasMore={nextPage}
                loader= {   <Container>
                                <SectionLoader/>
                            </Container>
                        }
                >
            <Users
              currentUserEmail={profile.email}
              userList={userList}
              onDelete={deleteUser}
              onEdit={editUser}
              loading={loading}
              totalEntries={props.totalEntries}
            />
                </InfiniteScroll>
            </div>
        </SectionGaurd> 
    </div>
  );
};
export default UsersViewer;
