import React from 'react'

const Stats= ({label,value,className}) => {
  return (
    <div className={className}>
        {label} : {value}
        
    </div>
  )
}

export default Stats