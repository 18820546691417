import React, { useState } from "react";
import styles from "./OverlayFilterButton.module.css";

import IMAGES from "../../../../assets/images/images";
import { Filters } from "./Filters";

const Overlay = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(0)
  const [isActive, setIsActive]= useState(false);

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleClear = () => {
    handleClose();
    props.filter({})
    setIsActive(false)
    setState(s => s + 1)
  }

  React.useImperativeHandle(ref, () => ({
    handleClick: handleClose,
  }));

  const onApplyFilters = (filters) => {
    console.log(filters);
    console.log('Submitted')
    // API call using filter
    props.filter(filters)
    setIsActive(true)
    handleClose()
  };
  const toggleFilter=()=>{
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <button className={[styles.applybtn, isActive? styles.active: null].join(" ")} onClick={toggleFilter}>
        Apply Filter
        <span>
          <img
            className={styles.filterIcon}
            src={isActive? IMAGES.filter_light : IMAGES.filter_dark}
            alt="checkIcon"
          ></img>
        </span>
      </button>

     
        <div className={styles.filterbox} style={{display:isOpen? "block":"none"}}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>Filter</div>
            <div className={styles.close}>
              <img alt="close" src={IMAGES.close} onClick={handleClose} />
            </div>
          </div>

          <Filters key={state} onClear={handleClear} onApply={onApplyFilters} />
        </div>
    </div>
  );
});

export default Overlay;
