import React, { Component, useEffect, useState } from "react";
import Container from "../../../components/sidebar/ContentContainer";
import {
  Formik,
  useField,
  FieldArray,
  useFormikContext,
  ErrorMessage,
} from "formik";
import styles from "./newService.module.css";
import {
  FormFieldLabel as Label,
  FormFieldLabel2,
  FormErrorLabel as ErrorLabel,
  FormFieldLabel5,
  FormFieldLabel3,
} from "../../../components/label/Labels";
import {
  FormTextInput as Input,
  FormTextArea,
} from "../../../components/input/inputs";
import { Submit } from "../../../components/button/buttons";
import * as Yup from "yup";
import {
  VALIDATION_ERROR_MESSAGE as MESSAGE,
  PHONE_REGEX,
  SMS_REGEX,
} from "../../../utils/constants";
import MultiDropDown from "./components/MultiSelectDropDown";
import DropDown from "./components/DropDown";
import { Switch, AMPMSwitch } from "../../../components/switch/Switch";
import Time from "../../../components/dropDown/TimeSelector";
import Section from "../../../components/form/Section";
import ImageDrop from "../../../components/imageUploader/imageDropZone.container";
import { showError, showSuccess } from "../../../utils/toasts";
import Places, {
  geocodeByPlaceId,
  getLatLng,
} from "../../../components/location/Places";
import { Navigate } from "react-router-dom";
import Map from "../../../components/map/map";
import CAN from "../../../casl/can";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import { Action, Resource } from "../../../casl/constants";
import { Link } from "react-router-dom";
import IMAGES from "../../../assets/images/images";
import ImageField from "../../../components/image-field/image-field.container";

const WorkingDays = [
  {
    label: "Monday",
    value: "monday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },
  {
    label: "Tuesday",
    value: "tuesday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },
  {
    label: "Wednesday",
    value: "wednesday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },
  {
    label: "Thursday",
    value: "thursday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },
  {
    label: "Friday",
    value: "friday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },
  {
    label: "Saturday",
    value: "saturday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },

  {
    label: "Sunday",
    value: "sunday",
    from: "",
    fap: "am",
    to: "",
    tap: "pm",
    closed: false,
  },
];
const BackButton = () => {
  return (
    <Link to="/services" className={`${styles.submit} ${styles.back}`}>
      <img src={IMAGES.chevron} alt="arrow" className={styles.backButton} />
      Back
    </Link>
  );
};

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

export const TextField = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <Label text={label} optional={optional} />
      <Input {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <Label text={label} optional={optional} />
      <FormTextArea {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const SelectDataGroup = ({ title, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  let _options = options.map((item) => ({ value: item.id, label: item.name }));
  return (
    <FieldWrapper>
      <FormFieldLabel3 text={title} required={props.required} />
      <DropDown
        options={_options}
        onChange={({ label, value }) => {
          helpers.setValue(value);
        }}
        value={
          _options
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const Place = ({ optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <Label text={"Search on Google Map"} optional={optional} />
      <Places
        placeholder="Type to search the location..."
        onChange={(place) => {
          helpers.setValue({
            name: place.value.structured_formatting.main_text,
            id: place.value.place_id,
          });
        }}
        defaultInputValue={field.value ? field.value.name : ""}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const Address = ({ optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const {
    values: {
      location: { place },
    },
    touched,
    setFieldValue,
  } = useFormikContext();
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;

  //This will be called automatically to fetch the address whenever a place is searched and selected
  useEffect(() => {
    let isCurrent = true;
    // your business logic around when to fetch goes here.
    if (place && place.id.trim() !== "") {
      geocodeByPlaceId(place.id).then((results) => {
        if (!!isCurrent) {
          // prevent setting old values
          setFieldValue(props.name, results[0].formatted_address);

          let geometry = null;
          getLatLng(results[0]).then((coordinates) => {
            setFieldValue("location.geometry", coordinates);
          });
        }
      });
    }
    return () => {
      isCurrent = false;
    };
  }, [place, setFieldValue, props.name]);

  return (
    <FieldWrapper>
      <Label text={"Address"} optional={optional} />
      <Input {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const GoogleMap = (props) => {
  const [field, meta, helpers] = useField(props);
  let _coordinate = field.value;
  let mapContainerStyle = {
    height: "400px",
    width: "600px",
  };
  return (
    <>
      {_coordinate ? (
        <FieldWrapper>
          <Map latlng={_coordinate} style={mapContainerStyle} />
        </FieldWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export const ImageUploader = ({
  optional = false,
  onError,
  onSuccess,
  label = "Add an icon for the service",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <Label text={label} optional={optional} />
      <ImageDrop
        onUpload={({ error, url, message }) => {
          if (error) {
            helpers.setError(message);
            onError(message);
          } else {
            onSuccess(message);
            helpers.setValue(url);
          }
        }}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

export const ImagePreview = () => {
  return (
    <FieldWrapper>
      <Label text={"Image Preview"} />
    </FieldWrapper>
  );
};

const ServiceType = ({ options, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;

  return (
    <FieldWrapper>
      <DropDown
        options={options}
        onChange={({ label, value }) => {
          helpers.setValue(value);
        }}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        hasError={hasError}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const NonLGBTSwitch = ({ optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FieldWrapper>
      <Label text={"Is this non-LGBT Service?"} optional />
      <Switch onChange={helpers.setValue} checked={field.value} />
    </FieldWrapper>
  );
};

const Tags = ({ options, communities_map, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <MultiDropDown
        options={options}
        onChange={(selected) => {
          helpers.setValue(selected.map((comm) => comm.value));
        }}
        value={field.value.map((value) => ({
          label: communities_map[value],
          value: value,
        }))}
        hasError={hasError}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CheckBox = ({ onChange, checked }) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className={styles.checkBox}
    />
  );
};

const OpeningHours = ({ optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const Row = ({ children }) => <div className={styles.row}>{children}</div>;
  const Col = ({ children, type }) => {
    let style;
    switch (type) {
      case "col1":
        style = styles.col1;
        break;
      case "col2":
        style = styles.col2;
        break;
      case "col3":
        style = styles.col3;
        break;
      default:
        style = styles.col1;
        break;
    }
    return <div className={style}>{children}</div>;
  };
  const Day = ({ children }) => (
    <label className={styles.day}>{children}</label>
  );
  const Header = ({ title }) => (
    <div className={styles.header}>
      <label>{title}</label>
    </div>
  );

  return (
    <FieldWrapper>
      <FormFieldLabel3 text={'Opening hours (Select "from" and "to" time or mark the service as closed)'} required={props.required}  />
      <Row>
        <Col type={"col1"}>
          <Header title={"Day"} />
        </Col>
        <Col type={"col2"}>
          <Header title={"From"} type="col2" />
        </Col>
        <Col type={"col2"}>
          <Header title={"To"} type="col2" />
        </Col>
        <Col type={"col3"}>
          <Header title={"Closed"} type="col3" />
        </Col>
      </Row>
      <FieldArray
        name="days"
        render={(arrayHelpers) => {
          return field.value.map((day, index) => {
            return (
              <Row>
                <Col type={"col1"}>
                  <Day>{day.label}</Day>
                </Col>

                <Col type={"col2"}>
                  <Time
                    hasError={hasError ? meta.error[index]?.from : false}
                    value={day.from}
                    onChange={({ label, value }) => {
                      let newValue = field.value;
                      newValue[index].from = value;

                      //When time is selected if the service is closed then it should automatically be set to false
                      const isClosed = field.value[index].closed;
                      if (isClosed) {
                        newValue[index].closed = false;
                      }

                      helpers.setValue(newValue);
                    }}
                  />
                  <AMPMSwitch
                    style={styles.apSwitch}
                    value={day.fap}
                    onChange={(value) => {
                      let newValue = field.value;
                      newValue[index].fap = value;
                      helpers.setValue(newValue);
                    }}
                  />
                </Col>

                <Col type={"col2"}>
                  <Time
                    hasError={hasError ? meta.error[index]?.to : false}
                    value={day.to}
                    onChange={({ label, value }) => {
                      let newValue = field.value;
                      newValue[index].to = value;
                      //When time is selected if the service is closed then it should automatically be set to false
                      const isClosed = field.value[index].closed;
                      if (isClosed) {
                        newValue[index].closed = false;
                      }
                      helpers.setValue(newValue);
                    }}
                  />

                  <AMPMSwitch
                    style={styles.apSwitch}
                    value={day.tap}
                    onChange={(value) => {
                      let newValue = field.value;
                      newValue[index].tap = value;
                      helpers.setValue(newValue);
                    }}
                  />
                </Col>

                <Col type={"col3"}>
                  <CheckBox
                    checked={day.closed}
                    onChange={(event) => {
                      let newValue = field.value;
                      const isClosed = event.target.checked;
                      newValue[index].closed = isClosed;
                      if (isClosed) {
                        newValue[index].from = "";
                        newValue[index].to = "";
                      }
                      helpers.setValue(newValue);
                    }}
                  />
                </Col>
              </Row>
            );
          });
        }}
      ></FieldArray>

      <ErrorMessage
        name="openingHours[0].from"
        render={(msg) => <ErrorLabel text={msg} />}
      />
      <ErrorMessage
        name="openingHours[0].to"
        render={(msg) => <ErrorLabel text={msg} />}
      />
      <ErrorMessage
        name="openingHours[1].from"
        render={(msg) => <ErrorLabel text={msg} />}
      />
      <ErrorMessage
        name="openingHours[0].to"
        render={(msg) => <ErrorLabel text={msg} />}
      />
    </FieldWrapper>
  );
};

const times = [
  "1:00",
  "1:05",
  "1:10",
  "1:15",
  "1:20",
  "1:25",
  "1:30",
  "1:35",
  "1:40",
  "1:45",
  "1:50",
  "1:55",
  "2:00",
  "2:05",
  "2:10",
  "2:15",
  "2:20",
  "2:25",
  "2:30",
  "2:35",
  "2:40",
  "2:45",
  "2:50",
  "2:55",
  "3:00",
  "3:05",
  "3:10",
  "3:15",
  "3:20",
  "3:25",
  "3:30",
  "3:35",
  "3:40",
  "3:45",
  "3:50",
  "3:55",
  "4:00",
  "4:05",
  "4:10",
  "4:15",
  "4:20",
  "4;25",
  "4:30",
  "4:35",
  "4:40",
  "4:45",
  "4:50",
  "4:55",
  "5:00",
  "5:05",
  "5:10",
  "5:15",
  "5:20",
  "5:25",
  "5:30",
  "5:35",
  "5:40",
  "5:45",
  "5:50",
  "5:55",
  "6:00",
  "6:05",
  "6:10",
  "6:15",
  "6:20",
  "6:25",
  "6:30",
  "6:35",
  "6:40",
  "6:45",
  "6:50",
  "6:55",
  "7:00",
  "7:05",
  "7:10",
  "7:15",
  "7:20",
  "7:25",
  "7:30",
  "7:35",
  "7:40",
  "7:45",
  "7:50",
  "7:55",
  "8:00",
  "8:05",
  "8:10",
  "8:15",
  "8:20",
  "8:25",
  "8:30",
  "8:35",
  "8:40",
  "8:45",
  "8:50",
  "8:55",
  "9:00",
  "9:05",
  "9:10",
  "9:15",
  "9:20",
  "9:25",
  "9:30",
  "9:35",
  "9:40",
  "9:45",
  "9:50",
  "9:55",
  "10:00",
  "10:05",
  "10:10",
  "10:15",
  "10:20",
  "10:25",
  "10:30",
  "10:35",
  "10:40",
  "10:45",
  "10:50",
  "10:55",
  "11:00",
  "11:05",
  "11:10",
  "11:15",
  "11:20",
  "11:25",
  "11:30",
  "11:35",
  "11:40",
  "11:45",
  "11:50",
  "11:55",
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
];
const TimeSchema = Yup.string().oneOf(times);

const TimingsSchema = Yup.object({
  closed: Yup.boolean().required(),
  fap: Yup.string().oneOf(["am", "pm"]).required(),
  from: Yup.string().when("closed", {
    is: true,
    then: Yup.string().nullable().max(0),
    otherwise: TimeSchema.required(
      `Select "from" time or mark the service as closed`
    ),
  }),
  tap: Yup.string().oneOf(["am", "pm"]).required(),
  to: Yup.string().when("closed", {
    is: true,
    then: Yup.string().nullable().max(0),
    otherwise: TimeSchema.required(
      `Select "to" time or mark the service as closed`
    ),
  }),
  label: Yup.string().max(25).required(),
  value: Yup.string().max(25).required().oneOf(["monday", "tuesday","wednesday","thursday","friday","saturday","sunday",]),
});
const OpeningHoursSchema = Yup.array()
  .of(TimingsSchema)
  .min(1)
  .nullable()
  .strict(true);

const validationSchema = Yup.object({
  serviceName: Yup.string()
    .max(50, "Service name must be 50 characters or less")
    .required(MESSAGE.Required),
  serviceType: Yup.string().required(MESSAGE.Required),
  communities: Yup.array().min(1, MESSAGE.Required),
  description: Yup.string().required(MESSAGE.Required),
  additionalInfo: Yup.string(),
  phone: Yup.string().matches(PHONE_REGEX, MESSAGE.Invalid_Phone).max(15),
  sms: Yup.string().matches(SMS_REGEX, MESSAGE.Invalid_SMS),
  email: Yup.string().email(MESSAGE.Invalid_Email),
  website: Yup.string().url(MESSAGE.Invalid_Url),
  serviceIcon: Yup.string().nullable(),
  status: Yup.string().required(),
  location: Yup.object({
    place: Yup.object({
      name: Yup.string(),
      id: Yup.string(),
    }).nullable(),
    address: Yup.string().nullable(),
  }),
  dg: Yup.string().required(MESSAGE.Required),
  openingHours: OpeningHoursSchema,
});

export default class SupportService extends Component {
  constructor(props) {
    super(props);
    let _initialValues = {
      dg:
        this.props.myRights && this.props.myRights.dg === "global"
          ? ""
          : this.props.myRights.dg,
      serviceName: "",
      description: "",
      additionalInfo: "",
      serviceType: "",
      nonLGBTService: false,
      communities: [],
      phone: "",
      sms: "",
      email: "",
      website: "",
      openingHours: WorkingDays.map(day =>({...day})),//array declare , use map in that.
      serviceIcon: null,
      status: "pending",
      location: {
        place: null,
        address: null,
        geometry: null,
      },
    };
    if (this.props.isEditMode) {
      const { details } = this.props;
      _initialValues.dg = details.dg;
      _initialValues.serviceName = details.serviceName;
      _initialValues.description = details.description;
      _initialValues.additionalInfo = details.additionalInfo;
      _initialValues.serviceType = details.serviceType;
      _initialValues.nonLGBTService = details.nonLGBTService;
      _initialValues.communities = details.communities;
      _initialValues.phone = details.phone;
      _initialValues.sms = details.sms;
      _initialValues.email = details.email;
      _initialValues.website = details.website;
      _initialValues.openingHours = details.openingHours;
      _initialValues.serviceIcon = details.serviceIcon;
      _initialValues.status = details.status;
      _initialValues.location = {
        place: details.location?.place,
        address: details.location?.address,
        geometry: details.location?.geometry,
      };
    }

    this.state = {
      submitted: false, 
      initialValues: _initialValues,
    };

    this.submit = this.submit.bind(this);
    this.createSupportService = this.createSupportService.bind(this);
    this.editSupportService = this.editSupportService.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.getServiceTypes();
    this.props.getCommunities();
    if (CAN(Action.LIST, Resource.DATAGROUPS)) {
      this.props.getDataGroups();
    }
  }

  submit(values, { setSubmitting }) {
    let payload = JSON.parse(JSON.stringify(values)); // disconnected copy
    if (this.props.isEditMode) {
      const {
        details: { serviceIcon: serverServiceIcon, id },
      } = this.props;

      if (values.serviceIcon === serverServiceIcon) {
        delete payload.serviceIcon;
      }
      this.editSupportService(payload, id).then((res) => setSubmitting(false));
    } else {
      this.createSupportService(payload).then((res) => setSubmitting(false));
    }
  }

  createSupportService(payload) {
    return this.props
      .add(payload)
      .then((result) => {
        if (result) {
          this.setState({ submitted: true });
          showSuccess("New support service created");
        } else {
          showError("Something went wrong while creating new service");
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        showError("New service cant be created");
        return Promise.resolve(true);
      });
  }

  editSupportService(payload, id) {
    return this.props
      .edit(payload, id)
      .then((result) => {
        if (result) {
          this.setState({ submitted: true });
          showSuccess("Service updated successfully");
        } else {
          showError("Service could not be edited. Please try again!");
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        showError("New service cant be created");
        return Promise.resolve(true);
      });
  }

  render() {
    const {
      types,
      communities,
      isEditMode,
      dataGroupsList,
      myRights,
      communities_map,
    } = this.props;
    const { submitted, initialValues } = this.state;
    return (
      <Container>
        <SectionGaurd canAccess={CAN(Action.CREATE, Resource.SERVICES)}>
          <div className={styles.container}>
            <BackButton />
            <h1 className={styles.styles}>
              {isEditMode ? "Edit Support Service" : "Add Support Service"}
            </h1>
            <Formik
              validateOnMount
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={this.submit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Section title={"Describe what the service is"}>
                  <FormFieldLabel5 text ="Service Name" required = {true}/>
                    <TextField
                      placeholder="Service Name"
                      id="serviceName"
                      name="serviceName"
                    />
                    <FormFieldLabel3 text ="Service Type" required = {true}/>
                    <ServiceType name="serviceType" options={types} />
                    
                    <NonLGBTSwitch name="nonLGBTService" />
                    <FormFieldLabel2 text ="Communities" required = {true}/>
                    <Tags
                      name="communities"
                      communities_map={communities_map}
                      options={communities}
                    />
                    <FormFieldLabel2 text ="Description" required = {true}/>
                    <TextArea
                      id="description"
                      name="description"
                    />
                    <TextArea
                      label={"Additional Information"}
                      id="additionalInfo"
                      name="additionalInfo"
                      optional={true}
                    />
                    {/* <ImageUploader
                      name="serviceIcon"
                      onError={showError}
                      onSuccess={showSuccess}
                      optional={true}
                    /> */}
                    <div className={styles.serviceimage}>
                      Support Service Image (Optional)
                    </div>
                    <ImageField name="serviceIcon" />
                    {/* <ImagePreview /> */}
                  </Section>
                  <Section title={"Where is the service located?"}>
                    {myRights && myRights.dg === "global" ? (
                      <SelectDataGroup
                        title={"Select Country" }
                        required
                        name="dg"
                        options={dataGroupsList}
                      />
                    ) : (
                      <></>
                    )}
                    <Place name="location.place" optional={true} />
                    <Address
                      id="location.address"
                      name="location.address"
                      optional={true}
                    />
                    <GoogleMap name="location.geometry" />
                  </Section>
                  <Section
                    title={"What are the operating hours for the service?"}
                  >
                    <OpeningHours name={"openingHours"}   required/>
                  </Section>
                  <Section title={"Contact information"}>
                    <TextField
                      label={"Phone Number"}
                      id="phone"
                      name="phone"
                      optional={true}
                    />
                    <TextField
                      label={"SMS"}
                      id="sms"
                      name="sms"
                      optional={true}
                    />
                    <TextField
                      label={"Email"}
                      id="email"
                      name="email"
                      optional={true}
                    />
                    <TextField
                      label={"Website"}
                      id="website"
                      name="website"
                      optional={true}
                    />
                  </Section>
                 {/* <div data-state={formik.isValid}>new</div> */}
                  <Submit
                   disabled={!formik.isValid}
                  //  || !formik.dirty
                    style={styles.submit} 
                    title={"Submit"}
                    isSubmitting={formik.isSubmitting}
                  />
                </form>
              )}
            </Formik>

            {submitted && <Navigate to="/services" />}
          </div>
        </SectionGaurd>
      </Container>
    );
  }
}
