import {connect} from "react-redux";
import Dashboard from "./Dashboard";
import {getIncidentsReport, GRAPH_TYPE} from "../../redux/actions/reports/incidents.actions";
import { dataGroups } from "../../redux/actions/userManagement/dataGroups.action";
import {getDemographicReport, DEMOGRAPHIC_GRAPH_TYPE} from "../../redux/actions/reports/demographicGraphs.action";
import {incidentsStats, incidentsOvertime} from "../../redux/actions/incidents/incidents.action"


const mapStateToProps = (state)=>{
    return {
        reports : state.reports.demographicGraphs,
        role: state.role,
        graphType : GRAPH_TYPE,
        Demographic_graphType:DEMOGRAPHIC_GRAPH_TYPE,
        totalReports : state.reports.incidents.totalReports,
        victim : state.reports.incidents.victim,
        time : state.reports.incidents.time,
        pod : state.reports.incidents.pod,
        county : state.reports.incidents.county,
        town : state.reports.incidents.town,
        incidentType : state.reports.incidents.incidentType,
        familiarity : state.reports.incidents.familiarity,
        relation : state.reports.incidents.relation,
        motivation : state.reports.incidents.motivation,
        consequence : state.reports.incidents.consequence,
        incidentsReportLoading: state.reports.incidents.incidentsReportLoading,
        myRights:state.myRights.myRights,
        dataGroupsList: state.dataGroups.dataGroups,
        archived: state.incidents.incidents.archived,
        seekingSupport: state.reports.incidents.seekingSupport,
        chronology: state.incidents.incidents.chronology,
        loadingOverTime: state.incidents.incidents.loadingOverTime,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        incidentsReport : (graphType, filters,totalCalls,currentIndex)=>{
            return dispatch(getIncidentsReport(graphType, filters,totalCalls,currentIndex))
        },
        getDataGroups:() => {
            return dispatch(dataGroups());
        },
        demographicIncidentsReport: (graphType, filters,totalCalls,currentIndex)=>{
            return dispatch(getDemographicReport(graphType, filters,totalCalls,currentIndex))
        },
        incidentsStats: ()=>{
            return dispatch(incidentsStats("archived"))
        },
        incidentsOvertime: (timeline, options)=>{
            return dispatch(incidentsOvertime(timeline, options))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

