import { FETCH_MYRIGHTS,UPDATE_MYRIGHTS} from "../../actions/actionTypes";
import { getAuth } from "firebase/auth";
const initialState = {
  myRights:null,
  loading: !getAuth().currentUser,
  loginType:null
};

const reducer = (state = initialState, action) => {
  const auth = getAuth();
  let provider=auth?.currentUser?.providerData[0].providerId;
  switch (action.type) {
    case FETCH_MYRIGHTS.REQUEST:
      return { ...state, loading: true };
    case FETCH_MYRIGHTS.FAILURE:
      return { ...state, loading: false };
    case FETCH_MYRIGHTS.SUCCESS:
      return { ...state,
         totalEntries :action.myRights.totalEntries,
          myRights:action.myRights,loginType:provider?provider:null, loading: false };
    case UPDATE_MYRIGHTS:
        return { ...state, myRights:action.myRights,loginType:provider?provider:null, loading: false };
    default:
      return state;
  }
};

export default reducer;
