import React, { Component, forwardRef, useEffect, useState } from "react";
import styles from "./newEvent.module.css";
import {
  Formik,
  Field,
  Form,
  useField,
  useFormikContext,
  hasError,
  ErrorMessage,
} from "formik";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import IMAGES from "../../../assets/images/images";
import DropDown from "./components/DropDown";
import { Navigate } from "react-router-dom";
import { TailSpinLoader } from "../../../components/loader/Loaders";
import { showError, showSuccess } from "../../../utils/toasts";
import { formatSecondsToDate, toMoment } from "../../../utils/dateTime";
import { DATE_FORMAT, EVENT_LOCATION_TYPE } from "../../../utils/constants";
import { getPropValue } from "../../../utils/objectOperations";
import { LocationAutocomplete } from "./components/LocationAutocomplete";
import Container from "../../../components/sidebar/ContentContainer";
import Section from "../../../components/form/Section";
import CAN from "../../../casl/can";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import { Action, Resource } from "../../../casl/constants";
import Time from "../../../components/dropDown/TimeSelector";
import { AMPMSwitch } from "../../../components/switch/Switch";
import Map from "../../../components/map/map";
import * as Yup from "yup";
import { VALIDATION_ERROR_MESSAGE as MESSAGE } from "../../../utils/constants";
import { DateTime } from "luxon";
import { TIMES_ZONES } from "../../../utils/constants";
import { Link } from "react-router-dom";
import RepeatRule from "./components/RepeatRule";
import ImageField from "../../../components/image-field/image-field.container";
import moment from 'moment';
import {
  FormFieldLabel2,
} from "../../../components/label/Labels";

const GLOBAL_USER = "global"
const ASPECT_RATIO_EVENTS= 384/184 // Events aspect ratio 1.5625/1. Followed figma aspect ratio of image.

const Categories = [
  { label: "Culture", value: "culture" },
  { label: "Meet up", value: "meetup" },
  { label: "Nightlife", value: "nightlife" },
  { label: "Pride", value: "pride" },
  { label: "Support groups", value: "support_groups" },
];
const EVENT_CATEGORIES_VALUES = Categories.map((type) => type.value);

const TimeField = ({ id, pod = "am", ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [time, setTime] = useState("");
  const [aMPMSwitch, setAMPMSwitch] = useState(pod);
  const Row = ({ children }) => <div className={styles.row}>{children}</div>;

  useEffect(() => {
    if (field.value) {
      let _split = field.value.split(" ");
      if (_split.length === 2) {
        setTime(_split[0]);
        setAMPMSwitch(_split[1]);
      }
    }
  }, [field.value]);

  useEffect(() => {
    if (time) {
      let _finalTime = time + " " + aMPMSwitch;
      helpers.setValue(_finalTime);
    }
  }, [time, aMPMSwitch]);

  return (
    <Row>
      <Time
        value={time}
        onChange={({ label, value }) => {
          setTime(value);
        }}
      />
      <AMPMSwitch
        style={styles.apSwitch}
        value={aMPMSwitch}
        onChange={(value) => {
          setAMPMSwitch(value);
        }}
      />
    </Row>
  );
};

const LocationType = ({ onTypeChange, type }) => {
  return (
    <div className={styles.locationType}>
      <label>Is the event online?</label>
      <Switch
        className={styles.locTypeSwitch}
        onChange={onTypeChange}
        checked={type}
      />
    </div>
  );
};

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={styles.dateInputButton}
    onClick={onClick}
    ref={ref}
  >
    <img className={styles.calendarIcon} src={IMAGES.calendar} />
    {value}
  </button>
));

const BackButton = () => {
  return (
    <Link to="/events" className={`${styles.submitBack} ${styles.back}`}>
      <img src={IMAGES.chevron} alt="arrow" className={styles.backButton} />
      Back
    </Link>
  );
};

const Submit = ({ onSubmit, loading, disabled }) => {
  if (loading) {
    return <TailSpinLoader />;
  }
  return (
    <button  type="submit" className={styles.submit}
      disabled={disabled}>
      Submit
    </button>
  );
};

const Label = ({ text }) => {
  return <label className={styles.label}>{text}</label>;
};

const VContainer = ({ children }) => {
  return <div className={styles.vContainer}>{children}</div>;
};

const HContainer = ({ children }) => {
  return <div className={styles.hContainer}>{children}</div>;
};
const GoogleMap = (props) => {
  const [field, meta, helpers] = useField(props);
  let _coordinate = {
    lat: field.value?.lat,
    lng: field.value?.lng,
  };
  let mapContainerStyle = {
    height: "400px",
    width: "600px",
  };
  return (
    <>
      {_coordinate.lat && _coordinate.lng ? (
        <Map latlng={_coordinate} style={mapContainerStyle} />
      ) : (
        <></>
      )}
    </>
  );
};
const ErrorField = ({ id, ...props }) => {
  //const [field, meta, styles] = useField(props);
  return (
    <div style={{ color: "red", marginTop: "-15px", marginBottom: "10px" }}>
      <ErrorMessage name={id}></ErrorMessage>
    </div>
  );
};

const yesterday = new Date(Date.now() - 86400000);

// const regMatch =
//   /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const validationSchema =(editMode = false) =>
 Yup.object().shape({
  eventName: Yup.string()
    .max(70, "Event name must be 70 characters or less")
    .required(MESSAGE.Required)
    .nullable(),
  details: Yup.string()
    .max(2000, "Event details must be 2000 characters or less")
    .required(MESSAGE.Required)
    .nullable(),
  link: Yup.string()
    .transform(s => s.startsWith('http') ? s : `http://${s}`) // 
    .url("Website should be a valid URL")
    // .required(MESSAGE.Required)
    .nullable(),
    
    // .matches(regMatch, "Website should be a valid URL"),
    // .matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   "Enter correct url!"
    // )
    // .required("Please enter website"),
    category: Yup.string()
      .oneOf(EVENT_CATEGORIES_VALUES)
      .required(MESSAGE.Required)
      .nullable(),

    startDate: Yup.date()
      .required(MESSAGE.Required)
      .nullable()
      .when({
        is: (v) => !v?.repeatRule && !editMode,
        then: (s) => s.min(yesterday, "Date cannot be in the past"),
      }),
    startTime: Yup.string().required(MESSAGE.Required).nullable(),
    endDate: Yup.date()
      // .required(MESSAGE.Required)
      .min(Yup.ref("startDate"), "End date can't be before start date")
      .nullable(),
    // .when("repeatRule",{
    //   is:rule =>!!rule,
    //   then:schema=>schema,
    //   // .required(MESSAGE.Required),
    // otherwise:schema=>schema.nullable()
    // }),
    repeatRule: Yup.string(),

    endTime: Yup.string()
      // .required(MESSAGE.Required)
      .nullable(),
    dg: Yup.string(),
    online: Yup.boolean(),
    city: Yup.string().when("online", {
      is: false,
      then: Yup.string().required(MESSAGE.Required).nullable(),
      otherwise: Yup.string().defined("city must be null").nullable(),
    }),
    location: Yup.object().when("online", {
      is: false,
      then: Yup.object({
        name: Yup.string(),
        id: Yup.string(),
      })
        .required(MESSAGE.Required)
        .nullable(),
      otherwise: Yup.object().defined("location must be null").nullable(),
    }),

    address: Yup.string().when("online", {
      is: false,
      then: Yup.string()
        .max(250, "Address must be 250 characters or less")
        .required(MESSAGE.Required)
        .nullable(),
      otherwise: Yup.string().defined("adress must be null").nullable(),
    }),
  });

export default class NewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
      submitted: false,
      isRepeatingEvent:false,
      formData: {
        startDate: null,
        endDate: null,
        online: false,
        city: null,
        address: null,
        location: this.props.isEditMode
          ? this.props.details?.location?.place
          : null,
        eventName: null,
        startTime: "",
        endTime: "",
        dg:
          this.props.myRights && this.props.myRights.dg === GLOBAL_USER
            ? ""
            : this.props.myRights.dg,
        link: "",
      },
      citiesOption: [],
      repeatRule: null,
    };
    this.onImageUpload = this.onImageUpload.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
  }

  componentDidMount() 
  {
    const { isEditMode } = this.props;
    if (CAN(Action.LIST, Resource.DATAGROUPS)) {
      this.props.getDataGroups();
    }
   
    if (isEditMode) {
      const {
        details: {
          event_name: eventName,
          details,
          category,
          event_time,
          location: { loc_type, place, address, city, geometry },
          dg,
          link,
          repeatRule,
          image_url,
        },
      } = this.props;
      /**
       * Date object (End date) is optional and could be null , hence fetching
       * it using the getPropValue instead of destructuring
       */
      const startDate = getPropValue(event_time, "start.date");
      const startTime = getPropValue(event_time, "start.time");
      const endDate = getPropValue(event_time, "end.date");
      const endTime = getPropValue(event_time, "end.time");

      const initialValues = {
        eventName,
        details,
        category,
        startDate: startDate ? moment(formatSecondsToDate(startDate)).toDate(): null,
        startTime,
        endDate: endDate ? moment(formatSecondsToDate(endDate)).toDate(): null, //endDate ? toMoment(endDate).toDate() : null,
        endTime,
        online: loc_type === EVENT_LOCATION_TYPE.Online,
        location: place,
        address,
        city,
        geometry,
        dg,
        link,
        repeatRule,
        image_url,
      };

      this.setState((oldState) => {
        return {
          ...oldState,
          formData: { ...oldState.formData, ...initialValues },
          isRepeatingEvent:initialValues.repeatRule
        };
      });
    }
    let _dg = this.props.myRights && this.props.myRights.dg;
    if (_dg != GLOBAL_USER) {     
      this.props.getCities(_dg);
    }
  }

  onImageUpload({ error, url, message }) {
    if (error) {
      showError(message);
    } else {
      this.setState({ imageUrl: url });
      showSuccess(message);
    }
  }

  submitForm(values, setSubmitting) {
    const { isEditMode } = this.props;
    const { isRepeatingEvent} = this.state;
    const {
      details,
      startDate,
      startTime,
      endDate,
      endTime = null,
      eventName,
      online,
      city,
      location,
      address,
      category,
      geometry = null,
      dg,
      link,
      repeatRule,
      image_url,
    } = values;
    /**
     * This date conversion accounts for the Country this event was
     * created for and not where the event was created from. For eg:
     *
     * The event could be created by an admin in India, however the
     * since the event is for (let's say) for UK then, the date has
     * to be saved as per UK time and not IST.
     */
    const dateFormat = "yyyy-M-d'T'HH:mm:ss z";

    const formattedStartDate = startDate
      ? DateTime.fromFormat(
          `${startDate.getFullYear()}-${
            startDate.getMonth() + 1
          }-${startDate.getDate()}T00:00:00 ${TIMES_ZONES[dg]}`,
          dateFormat,
          {
            setZone: true,
          }
        )
      : null;
   
    const formattedEndDate = endDate
      ? DateTime.fromFormat(
          `${endDate.getFullYear()}-${
            endDate.getMonth() + 1
          }-${endDate.getDate()}T23:59:59 ${TIMES_ZONES[dg]}`,
          dateFormat,
          {
            setZone: true,
          }
        )
      : null;

    const _startDate=  startDate ? formattedStartDate.toUnixInteger() : null;
    let _endDate = null ;
    if (isRepeatingEvent) {
      if (endDate) {
        _endDate = formattedEndDate.toUnixInteger();
      }
    } else {
      _endDate = null;
    }
    let payload = {
      image_url,
      details,
      category,
      status: "pending",
      event_name: eventName,
      event_time: {
        start: {
          time: startTime,
          date: _startDate,  
        },
        end: {
          time: endTime,
          date:_endDate,
        },
      },
      location: {
        loc_type: online
          ? EVENT_LOCATION_TYPE.Online
          : EVENT_LOCATION_TYPE.InPerson,
        place: location,
        city,
        address,
        geometry: online ? null : geometry,
      },
      dg,
      link,
      repeatRule,
      repeats: repeatRule ? true : false,
    };
    if (isEditMode) {
      const {
        details: { image_url, event_id },
      } = this.props;
      if (this.props.details.image_url === values.image_url) {
        delete payload.image_url;
      }
      delete payload.status; //removing status key too
      this.editEvent(payload, event_id).then((res) => setSubmitting(false));
    } else {
      this.createEvent(payload).then((res) => setSubmitting(false));
    }
  }

  createEvent(payload) {
    return this.props
      .newEvent(payload)
      .then((success) => {
        if (success) {
          this.setState({ submitted: true });
          showSuccess("Event added successfully");
        } else {
          showError("Event could not be created. Please try again!");
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        showError("Event could not be created. Please try again!");
        return Promise.resolve(true);
      });
  }

  editEvent(payload, id) {
    return this.props
      .edit(payload, id)
      .then((success) => {
        if (success) {
          this.setState({ submitted: true });
          showSuccess("Event updated successfully");
        } else {
          showError("Event could not be edited. Please try again!");
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        showError("Event could not be edited. Please try again!");
        return Promise.resolve(true);
      });
  }

  render() {
    const { submitted, formData } = this.state;
    const { submitting, isEditMode, dataGroupsList, myRights,cities = []} = this.props;
    const pageTitle = isEditMode ? "Edit the event" : "Create a new event";

    return (
      <Container>
        <div className={styles.container}>
          <SectionGaurd canAccess={CAN(Action.CREATE, Resource.EVENT)}>
            <BackButton />
            <h1 className={styles.pageTitle}>{pageTitle}</h1>
            <Formik
              validationSchema={validationSchema(isEditMode)}
              initialValues={{ ...formData }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                this.submitForm(values, setSubmitting);
              }}
            >
              {({
                isSubmitting,
                isValid,
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                helpers,
                touched,
                dirty,
                validateForm,
              }) => (
                <Form className={styles.form}>
                  <Section title={"Event details"}>
                  <FormFieldLabel2 text ="Event name" required = {true}/>
                    <Field
                      className={styles.textField}
                      id="eventName"
                      name="eventName"
                      placeholder="Pride Parade etc."
                    />
                    <ErrorField id="eventName" />
                    <FormFieldLabel2 text ="Description" required = {true}/>
                    <Field
                      className={styles.textArea}
                      id="details"
                      name="details"
                      placeholder="Add some details about the event."
                      as="textarea"
                    />
                    {errors.details ? (
                      <span className={styles.errorSpan}>{errors.details}</span>
                    ) : null}
                     <FormFieldLabel2 text ="Category" required = {true}/>
                    <Field
                      as="select"
                      id="category"
                      name="category"
                      component={DropDown}
                      options={Categories}
                      placeholder="Select a category"
                      onChange={(option) =>
                        setFieldValue("category", option.value)
                      }
                    />
                    {errors.category ? (
                      <span className={styles.errorSpan}>
                        {errors.category}
                      </span>
                    ) : null}

                    <Label text="Event Image" />
                    {/* <ImageField name="image_url" /> */}
                    <ImageField name="image_url" aspectRatio={ ASPECT_RATIO_EVENTS} // Events aspect ratio 1.5625/1.
                    // width={425} 
                    />

                    <Label text="Website" />
                    <Field
                      className={styles.textField}
                      id="link"
                      name="link"
                      placeholder="Provide a URL to the event Website"
                    />

                    <ErrorField id="link" />
                  </Section>

                  <Section title={"Event dates and timings"}>
                    <HContainer>
                      <VContainer>
                        <RepeatRule  enabled={this.state.isRepeatingEvent}setEnabled={(value)=>{
                          this.setState(s =>({...s,isRepeatingEvent:value}))
                          if(!value){
                            setFieldValue("repeatRule","")
                          }
                     
                        }} name={"repeatRule"}>
                    <HContainer>
                      <VContainer>
                      <FormFieldLabel2  text={`${!this.state.isRepeatingEvent ? 'Event': 'Start'} Date`} required = {true}/>
                        <Datepicker
                          placeholderText="start date"
                          dateFormat={DATE_FORMAT}
                          minDate={new Date()}
                          maxDate={
                            values.endDate
                              ? new Date(values.endDate)
                              : undefined
                          }
                          selected={values.startDate}
                          onChange={(date) => {
                            setFieldValue("startDate", date);
                          }}
                          customInput={<DateInput />}
                        />
                        <ErrorField id="startDate" />
                      </VContainer>
                      {this.state.isRepeatingEvent?<VContainer>
                        <Label text="End Date" />
                        <Datepicker
                          placeholderText="end date"
                          dateFormat={DATE_FORMAT}
                          minDate={
                            values.startDate
                              ? new Date(values.startDate)
                              : new Date()
                          }
                          selected={values.endDate}
                          onChange={(date) => {
                            setFieldValue("endDate", date);
                          }}
                          customInput={<DateInput />}
                        />
                        <ErrorField id="endDate" />
                      </VContainer>:null}
                    </HContainer>

                    <HContainer>
                      <VContainer>
                        <FormFieldLabel2 text="Start Time" required = {true} />
                        <TimeField id="startTime" name="startTime" pod="am" />
                        <ErrorField id="startTime" />
                      </VContainer>
                      <VContainer>
                        <Label text="End Time" />
                        <TimeField id="endTime" name="endTime" pod="pm" />
                        <ErrorField id="endTime" />
                      </VContainer>
                    </HContainer>

                        </RepeatRule>
                      </VContainer>
                    </HContainer>
                  </Section>

                  <Section title={"Event location"}>
                    <LocationType
                      onTypeChange={(isOnline) => {
                        setFieldValue("online", isOnline);
                        if (isOnline) {
                          setFieldValue("city", null);
                          setFieldValue("address", null);
                          setFieldValue("location", null);
                          setFieldValue("geometry", null);
                        } else if (!isOnline && isEditMode) {
                          const { place, address, city, geometry } =
                            this.props.details.location;
                          setFieldValue("city", city);
                          setFieldValue("address", address);
                          setFieldValue("location", place);
                          setFieldValue("geometry", geometry);
                        }
                        setTimeout(validateForm, 0);
                      }}
                      type={values.online}
                    />
                    {myRights && myRights.dg === GLOBAL_USER ? (
                      <React.Fragment>
                        <FormFieldLabel2 text="Select The Market For The Event" required={!values.online}/>
                        <Field
                          id="dg"
                          name="dg"
                          component={DropDown}
                          options={dataGroupsList.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder="Select a country"
                          onChange={(obj) => {
                            setFieldValue("dg", obj.value);
                            /**
                             * In global user the country code option will come to select.
                             */
                            this.props.getCities(obj.value)   
                          }}
                        />
                      </React.Fragment>
                    ) : (
                      <></>
                    )}

                    {!values.online && (
                      <>
                       <FormFieldLabel2 text ="City" required = {true}/>
                        <Field
                          id="city"
                          name="city"
                          component={DropDown}
                          isDisabled={!cities?.length}
                          options={cities}
                          placeholder="Select a city"
                          onChange={(option) =>
                            setFieldValue("city", option.value)
                          }
                        />
                        <ErrorField id="city" />
                        <FormFieldLabel2 text ="Event Location on Google Map" required = {true}/>
                        <Field
                          id="location"
                          name="location"
                          component={LocationAutocomplete}
                          defaultInputValue={values.location}
                          placeholder="Type to search the location..."
                        />
                        <ErrorField id="location" />

                        <FormFieldLabel2 text ="Address" required = {true}/>
                        <Field
                          className={styles.textField}
                          id="address"
                          name="address"
                          placeholder="Haggerston Park, Yorkton St, London E2 8NH"
                        />
                        <ErrorField id="address" />

                        <GoogleMap name="geometry" />
                      </>
                    )}
                  </Section>
                  <Submit loading={isSubmitting} 
                  disabled={!isValid || !dirty || (this.state.isRepeatingEvent && !values.repeatRule)}/>
                </Form>
              )}
            </Formik>
            {submitted && <Navigate to="/events" />}
          </SectionGaurd>
        </div>
      </Container>
    );
  }
}
