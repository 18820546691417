import React, {Component} from "react";
import styles from "./newsViewer.module.css"
import Container from "../../../components/sidebar/ContentContainer";
import {Item, ActionContainer, Status} from "../../../components/content/Containers"
import {Chevron, Delete, Publish} from "../../../components/content/Actions";
import {useNavigate} from "react-router-dom"
import Tabs, {Tab, TabList, TabPanel, TabPanels} from "../../../components/tabs/Tabs";
import { showError, showSuccess } from "../../../utils/toasts";
import InfiniteScroll from "react-infinite-scroll-component";

import { SectionLoader } from "../../../components/loader/Loaders";
import Stats from "../../../components/stats/stats";
const tabs = [
    {lable : "Gay Times", id: "gay-times"},
    {lable : "Diva News", id: "diva-magazine"}
]

function removeTrailingZeroes(date) {
  return date.replace(/[+]0{4}$/, "");
}

const NewsItem = (props)=>{
    const {title, creator, isoDate,pubDate, link, news_id, status, onPublish, publisher} = props
    // const showPublishButton = status === "new"
    const notPublished = status !== "published";
  const statusStyle = notPublished ? `${styles.pending}` : "";
    const navigate = useNavigate();
    return (
        <Item>
            <div className={styles.feedInfo}>
                <label className={styles.itemTitle}>{title}</label>
                <label className={styles.itemInfo}>{creator}</label>
                {/* <label className={styles.itemInfo}>{(isoDate)}</label> */}
                 <label className={styles.itemInfo}>{removeTrailingZeroes(pubDate)}</label> 
                 {/* <label className={styles.itemInfo}>{(publisher)}</label>  */}

            </div>
            
            <ActionContainer>
            <Status status={status} style={statusStyle} />
            <button
          className={
            styles.button +
            " " +
            (status === "published" ? styles.unpublish : styles.publish)
          }
          //  style={{

          // }}
          onClick={() => onPublish(news_id, status !== "published")}
        >
          {status === "published" ? "Unpublish" : "Publish"}
        </button>
                <Chevron onClick={()=>{
                    navigate(`/news/${news_id}`)

                }}/>
            </ActionContainer>
        </Item>
    );
}

const Separator = ()=>{
    return (
        <div className={styles.separator}></div>
    );
}

const CustomTab = (props)=>{
    const {lable, selected} = props
    let style = selected ? `${styles.tab} ${styles.selectedTab}` : styles.tab
    
    return (
        <div className={style}>
            {lable}
        </div>
    );
}


function NewsList({ feeds, onNext, nextPage, onPublish }) {
  return (
    <InfiniteScroll
      dataLength={feeds.length} //This is important field to render the next data
      next={onNext}
      hasMore={nextPage}
      loader={
        <Container>
          <SectionLoader />
        </Container>
      }
    >
      {feeds
        .sort((a, b) => (new Date(a.isoDate) < new Date(b.isoDate) ? 1 : -1))
        .map((item) => {
          return <NewsItem key={item.id} {...item} onPublish={onPublish} />;
        })}
    </InfiniteScroll>
  );
}



class NewsViewer extends Component {

    constructor(props){
        super(props)
        this.publishNews = this.publishNews.bind(this)
        this.state = {
          activeTab : tabs[0].id
        }
    }

    componentDidMount(){
      this.props.changeTab(tabs[0].id)
        this.props.fetchNews(null,tabs[0].id)
    }

    componentWillUnmount() {
      this.props.changeTab(null)
    }

    publishNews(id,publish =true){
        return this.props.publish(id, {status: publish ?   "published" : "unpublished"})
        .then(success => {
            if(!success){
              showError("Something went wrong");
            } else {
              showSuccess(
                `News ${
                  publish ? "published" : "unpublished"
                } successfully`
              );
                this.props.fetchNews(null,this.props.selectedTab)
            }
        })
    }

   render(){
        const { feed = [], loading, next, selectedTab } = this.props;
        const tabData = tabs //pull this data from API eventually

        return (
          <Container>
            <Tabs initialTab={tabData[0].id}>
              <TabList>
                {tabData.map((tab, index) => (
                  <Tab
                    key={index}
                    {...tab}
                    component={CustomTab}
                    onSelect={() => {
                      this.props.fetchNews(null, tab.id);
                      this.props.changeTab(tab.id);
                    }}
                  />
                ))}
              </TabList>
              
              {loading ? null : <Stats className={styles.total} label= "Total News" value={this.props.totalEntries}/>}

              <Separator />

              {loading && !feed.length ? (
                <Container>
                  <SectionLoader />
                </Container>
              ) : (
                <TabPanels>
                  {tabData.map((tab, index) => (
                    <TabPanel {...tabData[index]} key={tab.id}>
                      <NewsList
                        feeds={feed}
                        nextPage={next}
                        onPublish={this.publishNews}
                        onNext={() => this.props.fetchNews(next, selectedTab)}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              )}
            </Tabs>
          </Container>
        );
    }
}


export default NewsViewer;
