import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import Switch from "react-switch";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Formik, Form, useField } from "formik";
import React, { Fragment, useMemo, useState, Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link, Navigate } from "react-router-dom";
import IMAGES from "../../../assets/images/images";
import { Submit } from "../../../components/button/buttons";
import Section from "../../../components/form/Section";
import Container from "../../../components/sidebar/ContentContainer";
import {
  FormFieldLabel as Label,
  FormFieldLabel2,
  FormErrorLabel as ErrorLabel,
  FormFieldLabel3,
  FormFieldLabel5,
} from "../../../components/label/Labels";
import {
  FormTextInput as Input,
  FormTextArea,
} from "../../../components/input/inputs";
import styles from "./NewInformation.module.css";
import { Action, Resource } from "../../../casl/constants";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import CAN from "../../../casl/can";
import { showError, showSuccess } from "../../../utils/toasts";
import { FormFieldLabel } from "../../../components/label/Labels";
import * as Yup from "yup";
import { VALIDATION_ERROR_MESSAGE as MESSAGE } from "../../../utils/constants";
import ImageField from "../../../components/image-field/image-field.container";
import purify from "sanitize-html"

const BackButton = () => {
  return (
    <Link to="/information" className={`${styles.submitBack} ${styles.back}`}>
      <img src={IMAGES.chevron} alt="arrow" className={styles.backButton} />
      Back
    </Link>
  );
};

const editorLabels = {

  // BlockType
  
  'components.controls.blocktype.h3': 'Section',
  'components.controls.blocktype.normal': 'Body',

};

const getReadingTime =(html)=> {
  const text = document.createElement("div").innerHTML = html;
  
  const words = text
    .trim()
    .split(/\s+/)
    .filter(word => word !== "")
    .length;

  const wpm = 225;
  // const time = Math.ceil(words / wpm);
  const time = (words / wpm);
  return +time.toFixed(0);
}


const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};


export const TextField = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <Label text={label} optional={optional} />
      <Input {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(45, "Title name must be 45 characters or less")
    .required(MESSAGE.Required)
    .nullable(),
  image_url: Yup.string().required(MESSAGE.Required).nullable(),
});
export default class NewInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
      submitted: false,
      formData: { htmlBody: "", title: "", image_url: "" },
      editorStates: [EditorState.createEmpty()],
      readingTime: 0,
      provideSupport: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.createInformation = this.createInformation.bind(this);
    this.editInformation = this.editInformation.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      const { details } = this.props;
      const { contentBlocks, entityMap } = htmlToDraft(details.htmlBody);
      this.setState({
        ...this.state,
        formData: details,
        editorStates: [
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlocks, entityMap)
          ),
        ],
        readingTime: getReadingTime(details.htmlBody) || 0,
        provideSupport: details.provideSupport,
      });
    }
  }

  submitForm(raw, setSubmitting) {
    const dom = new DOMParser()
    const doc = dom.parseFromString(raw.htmlBody, 'text/html');
    doc
    .documentElement
      .querySelectorAll("h3")
      .forEach((node) => {
        if (node instanceof HTMLHeadingElement) {
          node.style.color = 'rgb(16, 0, 69)';
          node.childNodes.forEach(child => {
            if(child instanceof HTMLSpanElement) {
              child.style.color = 'rgb(16, 0, 69)' 
            }
          })
        }
      });
    const values = { ...raw, htmlBody: purify(doc.body.innerHTML), title:purify(raw.title) };
    // const values = { ...raw, htmlBody: doc.body.innerHTML };
    const { isEditMode } = this.props;

    if (isEditMode) {
      const {
        details: { information_id },
      } = this.props;

      const data = { ...values };
      if (data.image_url === this.props.details.image_url) {
        delete data.image_url;
      }
      this.editInformation(data, information_id).then((res) =>
        setSubmitting(false)
      );
    } else {
      this.createInformation({ ...values, status: "pending" }).then((res) =>
        setSubmitting(false)
      );
    }
  }

  createInformation(payload) {
    let _payload = payload;
    _payload["readingTime"] = getReadingTime(payload.htmlBody);
    return this.props
      .newInformation(_payload)
      .then((success) => {
        if (success) {
          this.setState({ submitted: true });
          showSuccess("Information added successfully");
        } else {
          showError("Information could not be created. Please try again!");
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        showError("Information could not be created. Please try again!");
        return Promise.resolve(true);
      });
  }

  editInformation(payload, id) {
    let _payload = payload;
    _payload["readingTime"] = getReadingTime(payload.htmlBody);
    delete _payload['createdAt'];
    delete _payload['createdBy'];
    return this.props
      .edit(payload, id)
      .then((success) => {
        if (success) {
          this.setState({ submitted: true });
          showSuccess("Information updated successfully");
        } else {
          showError("Information could not be edited. Please try again!");
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        showError("Information could not be edited. Please try again!");
        return Promise.resolve(true);
      });
  }

  onEditorStateChange(newEditorState, index) {
    const states = [...this.state.editorStates];
    states[index] = newEditorState;
    this.setState({
      editorStates: states,
      readingTime: getReadingTime(this.getHtmlFromEditor(newEditorState)),
    });
  }

  getHtmlFromEditor(draft) {
    return draftToHtml(convertToRaw(draft.getCurrentContent()));
  }

  render() {
    const { submitted, formData, provideSupport, readingTime } = this.state;
    const { submitting, isEditMode } = this.props;
    const pageTitle = isEditMode ? "Edit Article" : "Create Article";

    return (
      <Container>
        <div className={styles.container}>
          <SectionGaurd canAccess={CAN(Action.CREATE, Resource.INFORMATION)}>
            <BackButton />
            <h1 className={styles.pageTitle}>{pageTitle}</h1>
            <Formik
              validateOnMount
              validationSchema={validationSchema}
              initialValues={{ ...formData }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                this.submitForm(
                  {
                    ...values,
                    provideSupport: this.state.provideSupport,
                    htmlBody: this.getHtmlFromEditor(
                      this.state.editorStates[0]
                    ),
                  },
                  setSubmitting
                );
              }}
            >
              {(formik) => (
                <Form className={styles.form}>
                  <Section title={"Describe what the Article is"}>
                  <FormFieldLabel5 text ="Title" required = {true}/>
                    <TextField
                      id="title"
                      placeholder="Enter Title (Max 45 characters)"
                      name="title"
                    />
                      <FormFieldLabel5 text ="Article Image" required = {true}/>
                    <div className={styles.articleimage}></div>
                    <div className={styles.imageUploader}>
                      <ImageField name="image_url" />
                    </div>

                    {this.state.editorStates.map((editorState, index) => (
                      <Fragment key={index}>
                        <div className={styles.description}>Description</div>
                        <Editor
                          localization={{
                            locale: "en",
                            translations: editorLabels,
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              // "fontSize",
                              "list",
                              "textAlign",
                              // "colorPicker",
                              "history",
                            ],
                            inline: {
                              options: [
                                "bold",
                                "italic",
                                "strikethrough",
                                "monospace",
                              ],
                            },
                            blockType: {
                              inDropdown: false,
                              options: ["Normal", "H3"],
                            },
                          }}
                          editorState={editorState}
                          toolbarClassName={styles.toolbar}
                          wrapperClassName={styles.editor}
                          editorClassName={styles.editorTextBox}
                          onEditorStateChange={(newState) =>
                            this.onEditorStateChange(newState, index)
                          }
                        />
                      </Fragment>
                    ))}
                      <span className={styles.readingtime}>
                      Reading Time - {" "}
                      {readingTime < 1
                        ? "Quick read"
                        : `${readingTime} minute(s)`}
                      </span>
                      <div className={styles.switchContainer}>
                        <FormFieldLabel text=" Include Provide Support banner at the end of the article" />

                        <Switch
                          name="provideSupport"
                          onChange={(value) => {
                            this.setState({
                              provideSupport: value,
                            });
                          }}
                          checked={provideSupport}
                        />
                      </div>

                    <div className={styles.submitCenter}>
                      <Submit
                        disabled={!formik.isValid}
                        style={styles.submit}
                        title={"Submit"}
                        isSubmitting={formik.isSubmitting}
                      />
                    </div>
                  </Section>
                </Form>
              )}
            </Formik>
            {submitted && <Navigate to="/information" />}
          </SectionGaurd>
        </div>
      </Container>
    );
  }
}
