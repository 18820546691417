import { connect } from "react-redux";
import UsersViewer from "./UsersViewer";
import { getUsers } from "../../../redux/actions/userManagement/getUsers.actions";
import { deleteUser } from "../../../redux/actions/userManagement/deleteUser.action";
import {startEditing} from "../../../redux/actions/userManagement/EditUser.action"

const mapStateToProps = (state) => {
  return {
    userList: state.getUsers.users,
    loading: state.getUsers.loading,
    nextPage:state.getUsers.next,
    profile : state.myRights.myRights,
    totalEntries: state.getUsers.totalEntries
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (isLoadMore,next) => {
      return dispatch(getUsers(isLoadMore,next));
    },
    deleteUser: (uid) => {
      return dispatch(deleteUser(uid));
    },
    edit: (details)=>{
      return dispatch(startEditing(details))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersViewer);
