import React, { useEffect, useState } from "react";
import styles from "./newUser.module.css";
import { PrimarySwitch } from "../../../components/switch/Switch";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
  FormFieldLabel2,
} from "../../../components/label/Labels";
import { FormTextInput as Input } from "../../../components/input/inputs";
import { Formik, Field, Form, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { Submit } from "../../../components/button/buttons";
import DropDown from "./components/DropDown";
import Tooltip from "../../../components/tooltip/Tooltip";
import { showError, showSuccess } from "../../../utils/toasts";
import { Navigate } from "react-router-dom";
import Section from "../../../components/form/Section";
import _ from "underscore";
import { PHONE_REGEX } from "../../../utils/constants";
import {showConfirmation, BUTTON_TYPES} from "../../../components/alert/Confirmation";
import { SectionLoader} from "../../../components/loader/Loaders";
import {TENANT_DOMAIN} from "../../../config"
import {resetPassword} from "../../../authActions/authActions"

const countryCodes = [
  { label: "UK-44", value: 44 },
  { label: "CZE-420", value: 420 },
  { label: "ALB-355", value: 355 },
];

const Roles = ({ name, data, ...props }) => {
  return (
    <div className={styles.functionalRolesContainer}>
      {
        Object.keys(data).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Label text={item} />
                <Role name={name} functionalRoles={data} data={data[item]} />
              </React.Fragment>
            );
          }
        )
      }
    </div>
  );
};

const Role = ({ name,functionalRoles, data, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [dataGroup,setDataGroup] = useState('');
  const {
    values: { group,isGroupVisible },
    setFieldValue,
    validateForm,
    validateField
  } = useFormikContext();

  useEffect(()=>{
    setDataGroup('');
    setFieldValue('group','');
    setFieldValue('isGroupVisible',false);
    let _dg=  field.value.assignedRoles.length>0?field.value.assignedRoles[0]:''
    Object.keys(functionalRoles).forEach((key) => {
      let data = _.where(functionalRoles  [key], {
        id: _dg,
      });
      if (data.length > 0) {
        setDataGroup(data[0].data_access);
        if(data[0].data_access==='global'){
          setFieldValue('group','global');
          setFieldValue('isGroupVisible',false);
        }else{
          setFieldValue('group',group);
          setFieldValue('isGroupVisible',true);
        }
      }
    });
    validateField('group')
  },[field.value.assignedRoles,group])

  return (
    <React.Fragment>
      {data.map((item, index) => {
        return (
          <div className={styles.roleContainer} key={index}>
            <label key={index} className={styles.displayFlex}>
              <Field
                type="checkbox"
                name={name}
                value={item.id}
                disabled={
                  dataGroup
                    ? dataGroup === item.data_access
                      ? false
                      : true
                    : false
                }
              />
              {item.name}
            </label>
            {dataGroup ? (
              dataGroup !== item.data_access ? (
                <Tooltip info={"Not allowed"} style={styles.tooltipStyle} />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

const Permissions = ({ functionalRolesMaster, roleMaster, ...props }) => {
  const [permissions, setPermissions] = useState([]);
  const {
    values: { assignedRoles },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    //get roles from function roles master
    let _permissions = [];
    let _roleMaster = JSON.parse(JSON.stringify(roleMaster)); // disconnected copy
    assignedRoles.forEach((element) => {
      Object.keys(functionalRolesMaster).forEach((key) => {
        let data = _.where(functionalRolesMaster[key], {
          id: element,
        });
        if (data.length > 0) {
          _permissions = [..._permissions, ...data[0].roles];
        }
      });
    });
    Object.keys(_roleMaster).forEach((key) => {
      _roleMaster[key].forEach((element) => {
        element.isSelected = false;
        if (_permissions.indexOf(element.id) !== -1) {
          element.isSelected = true;
        }
      });
    });
    setPermissions(_roleMaster);
  }, [assignedRoles, roleMaster, functionalRolesMaster]);

  return (
    <div className={styles.permissionsContainer}>
      {Object.keys(permissions).map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className={styles.permissionCol}>
              <Label text={item} />
              <Permission data={permissions[item]} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Permission = ({ data, ...props }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <>
            {item.isSelected ? (
              <div className={styles.permission} key={index}>
                <div key={index}>{item.name}</div>
                <Tooltip info={item.description} style={styles.tooltipStyle} />
              </div>
            ) : (
              <></>
            )}
          </>
        );
      })}
    </>
  );
};
const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label,disabled, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <FormFieldLabel2 text={label} required={props.required} />
      <Input {...field} {...props} style={inputStyle}  disabled={disabled}/>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const PhoneField = ({ label,disabled, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <div className={styles.phoneContainer}>
      <Label text={label} />
      <Input {...field} {...props} style={inputStyle}  disabled={disabled}/>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </div>
  );
};

const SelectUserGroup = ({ title, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  let _options=options.map((item) => ({ value: item.id, label: item.name }));
  return (
    <FieldWrapper>
      <FormFieldLabel2 text={title} required={props.required} />
      <DropDown
        options={_options}
        onChange={({ label, value }) => {
          helpers.setValue(value);
        }}
        value={_options?_options.find((item)=>item.value===field.value):''}
        hasError={hasError}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const SelectCountryCode = ({ title, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const style={
    width:180,
    marginRight:16
  }
  return (
    <FieldWrapper>
        <Label text={title} />
        <DropDown
          options={options}
          onChange={({ label, value }) => {
            helpers.setValue(value);
          }}
          value={options?options.find((item)=>item.value===field.value):''}
          hasError={hasError}
          customStyles={style}
        />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};


const NewUser = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const isEditMode=props.isEditMode;
  const pageTitle = isEditMode?"Edit user details":"Create new user";

  let _initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    group: '',   // conside group as dg
    assignedRoles: [],
    isGroupVisible:false
  };
  if(isEditMode){
    const details=props.userDetails;
    let _displayName=details.displayName.split(' ');
    _initialValues = {
      firstName: _displayName[0],
      lastName:_displayName[1],
      email: details.email,
      group: details.dg,
      assignedRoles:details.functionalRoles,
      isGroupVisible:details.dg==="global"?false:true,
    };
  }
  const initialValues=_initialValues;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(25, "Must be 15 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .max(25, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    group:  Yup.string().required("Required"),
    assignedRoles: Yup.array().min(1, "Required"),
  });
  useEffect(() => {
    props.getFunctionalRoles();
    props.getRolesMaster();
    props.getDataGroups();
  }, []);

  const createUser = (payload) => {
      return props.add(payload)
      .then((result) => {
        if (result.status) {
          showSuccess("New user created");
          setIsSubmit(true);
          /** Reset Password email will be sent only to non-VF users, 
           * VF users will recieve email verification link once user logged in from own credentials
           * Current(admin) user cannnot send verification email for the newly created users 
           * because of the way Identity platform/firebase Auth client SDK designed
           */
          const isNonVFUser = payload.email.toLowerCase().endsWith(TENANT_DOMAIN)?false:true
          if(isNonVFUser){
            resetPassword(payload.email)
          }
        } else {
          setIsSubmit(false);
          let errorCode=result.error.code;
          let error_msg = result.error.message
          // if(errorCode==='auth/email-already-exists'){
          //   error_msg='The email address is already in use by another account. Please contact admin'
          // }else{
            if(!error_msg){
            error_msg='Something went wrong while creating new user'
          }
          showError(error_msg);
        }
        return Promise.resolve(true);// should always return true to hide formik loader
      })
      .catch((error) => {
        setIsSubmit(false);
        showError("New user can't be created");
        return Promise.resolve(true); ;// should always return true to hide formik loader
      })
  };

  const editUser = (payload,id) => {
      return props
        .edit(payload,id)
        .then((result) => {
          if (result) {
            showSuccess("User details updated");
            setIsSubmit(true);
          } else {
            setIsSubmit(false);
            showError("Something went wrong while updating user details");
          }
          return Promise.resolve(true);// should always return true to hide formik loader
        })
        .catch((error) => {
          setIsSubmit(false);
          showError("New user cant be created");
          return Promise.resolve(true);// should always return true to hide formik loader
        })
  };

  const roleMaster = _.groupBy(
    props.roleMasterList,
    (currentObject) => currentObject.domain.label
  );
  const functionalRolesMaster = _.groupBy(
    props.functionalRolesList,
    (currentObject) => currentObject.domain.label
  );
  const dataGroupMaster = props.dataGroupsList;
  
  return (
    <div className={styles.container}>
      <h1 className={styles.pageTitle}>{pageTitle}</h1>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={(values,{ setSubmitting }) => {
          let _isVfUser=values.email.toLowerCase().endsWith(TENANT_DOMAIN)
          let payload = {
            display_name: values.firstName + " " + values.lastName,
            email: values.email,
            vf_user: _isVfUser,
            dg: values.group,
            functional_roles:values.assignedRoles
          };
          if(isEditMode){
            delete payload.display_name;
            delete payload.email;
            delete payload.vf_user;
            editUser(payload,props.userDetails.uid).then(res=>setSubmitting(false));
          }else{
            createUser(payload).then(res=>setSubmitting(false));
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Section title={"User details"}>
              <TextField
                label="First Name"
                name="firstName"
                type="text"
                placeholder="Jane"
                disabled={isEditMode}
                required={true}
              />
              <TextField
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Doe"
                disabled={isEditMode}
                required={true}
              />
              <TextField
                label="Email Address"
                name="email"
                type="email"
                placeholder="jane@formik.com"
                disabled={isEditMode}
                required={true}
              />
              {/* <div className={styles.numberContainer}>
                <SelectCountryCode
                  title={"Country Code"}
                  name="cc"
                  options={countryCodes}
                />
                <PhoneField
                  label={"Phone Number"}
                  name="phone"
                  type="number"
                  placeholder="Phone Number"
                  disabled={false}
                />
              </div> */}
            </Section>
            <Section title={"Select Role"}>
              {
                 props.functionalRolesListLoading ?
                 <SectionLoader />:
                 (
                  <div className={styles.displayFlex}>
                    <Roles name="assignedRoles" data={functionalRolesMaster}/>
                    <Permissions
                      functionalRolesMaster={
                        functionalRolesMaster ? functionalRolesMaster : []
                      }
                      roleMaster={roleMaster ? roleMaster : []}
                    />
                  </div>
                 )
              }
              {
                props.values.isGroupVisible? <SelectUserGroup
                title={"Select Country"} required={true}
                name="group"
                options={dataGroupMaster}
              />:<></>
              }
            </Section>
            <Submit
                  style={styles.submit}
                  title={"Submit"}
                  disabled={Object.keys(props.errors).length}
                  isSubmitting={props.isSubmitting}
            />
          </form>
        )}
      </Formik>
      {isSubmit && <Navigate to="/users" />}
    </div>
  );
};

export default NewUser;
