import { connect } from "react-redux";
import Services from "./ServicesViewer";
import {getServices} from "../../../redux/actions/supportServices/supportServices.actions"
import {updateSupportService, deleteSupportService ,startEditing} from "../../../redux/actions/supportServices/supportService.action"
import {supportServiceTypes} from "../../../redux/actions/supportServices/serviceTypes.actions"


const mapStateToProps = (state)=>{
    return {
        services: state.supportServices.services.data,
        nextPage: state.supportServices.services.next,
        loading:state.supportServices.services.loading.Services,
        serviceTypes: state.supportServices.serviceTypes,
        totalEntries: state.supportServices.services.totalEntries,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getServices: (isLoadMore,nextPage)=>{
            return dispatch(getServices(isLoadMore,nextPage))
        },
        delete: (serviceId)=>{
            return dispatch(deleteSupportService(serviceId))
        },
        publish: (payload, serviceId)=>{
            return dispatch(updateSupportService(payload, serviceId))
        },
        edit: (details)=>{
            return dispatch(startEditing(details))
        },
        getServiceTypes : ()=>{
            return dispatch(supportServiceTypes())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);