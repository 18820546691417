import { FETCH_INCIDENTS, FETCH_INCIDENTS_STATS, FETCH_INCIDENTS_SUBMITTED_OT } from "../../actions/actionTypes";

const initialState = {
  incidents:[],
  next: null,
  loading: true,
  loadingOverTime: false,
  archived: "0",
  chronology : 0,
  totalEntries: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INCIDENTS.REQUEST:
      return { ...state, loading: true };
    case FETCH_INCIDENTS.FAILURE:
      return { ...initialState, loading: false };
    case FETCH_INCIDENTS.SUCCESS:
      if(action.obj.isLoadMore){
        return { ...state, incidents: [...state.incidents,...action.obj.data.incidents], next: action.obj.data.next, loading: false };
      }else{
        return { ...state, totalEntries: action.obj.data.totalEntries, incidents: [...action.obj.data.incidents], next: action.obj.data.next, loading: false };
      }
    case FETCH_INCIDENTS_SUBMITTED_OT.REQUEST:
      return {...state, loadingOverTime: true}
    case FETCH_INCIDENTS_SUBMITTED_OT.SUCCESS:
      return { ...state, chronology: action.data, loadingOverTime: false };
    case FETCH_INCIDENTS_SUBMITTED_OT.FAILURE:
      return { ...state, loadingOverTime: false };
    case FETCH_INCIDENTS_STATS.SUCCESS: {
      return {...state, archived: action.stats}
    }
    default:
      return state;
  }
};

export default reducer;