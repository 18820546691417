import { useRef, useState } from "react";
import styles from "./Filters.module.css";
import {
  ButtonSecondary,
  ButtonPrimary,
} from "../../../../components/button/buttons";
import { FilterById, MoreFilters } from "./FilterCollections";

const ROOT_FILTERS = {
  FILTER_BY_ID: "FILTER_BY_ID",
  MORE_FILTERS: "MORE_FILTERS",
};

const FILTERS_LIST = [
  {
    value: ROOT_FILTERS.FILTER_BY_ID,
    label: "Filter by Incident Number",
    children: <FilterById />,
  },

  {
    value: ROOT_FILTERS.MORE_FILTERS,
    label: "More Filters",
    children: <MoreFilters />,
  },
];


export const Filters = ({ onClear, onApply }) => {
  const [selectedFilter, setSelectedFilter] = useState(
    ROOT_FILTERS.FILTER_BY_ID
  );

  const formRef = useRef();

  const [isDisabled, setIsDisabled] = useState(true);

  const handleButtonState = (selectedFilterValues) => {
    console.log(selectedFilterValues);
    const isAtleastOneSelected = Object.values(selectedFilterValues).some(
      (value) => !!value
    );

    setIsDisabled(!isAtleastOneSelected);

    return isAtleastOneSelected;
  };

  return (
    <form
      ref={formRef}
      onChange={(e) => {
        const selectedFilterValues = Object.fromEntries(
          new FormData(e.currentTarget)
        );
        handleButtonState(selectedFilterValues);
      }}
      onSubmit={(e) => {
        e.preventDefault();

        const selectedFilterValues = Object.fromEntries(
          new FormData(e.currentTarget)
        );
        console.log(selectedFilterValues);
        const isAtleastOneSelected = handleButtonState(selectedFilterValues);

        if (
          isAtleastOneSelected &&
          e.nativeEvent.submitter.getAttribute("data-ignore") !== "true"
        ) {
          onApply(selectedFilterValues);
        }
      }}
    >
      <ul className={`${styles.filterList} ${styles.filters}`}>
        {FILTERS_LIST.map((item) => (
          <li className={styles.filterItem} key={item.value}>
            <RootFilter
              {...item}
              isActive={selectedFilter === item.value}
              onSelected={() => {
                setSelectedFilter(item.value);
                setTimeout(()=>{
                  setIsDisabled(true);
                },0)

              }}
            />
          </li>
        ))}
      </ul>
      <div className={styles.footer}>
        <button className={styles.closeButton} type="button" onClick={onClear}>
          Clear All
        </button>
        <button
          className={styles.applyButton}
          type="submit"
          disabled={isDisabled}
        >
          Apply
        </button>
      </div>
    </form>
  );
};

const RootFilter = ({ value, label, isActive, children, onSelected }) => {
  const id = `${value}-filter`;

  return (
    <fieldset data-active={isActive} className={styles.container}>
      <legend role="button" className={styles.checkbox__label}>
        <input id={id} type="radio" checked={isActive} onChange={onSelected} />
        <label htmlFor={id}>{label}</label>
      </legend>

      {isActive ? children : null}
    </fieldset>
  );
};
