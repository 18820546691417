import NewsViewer from "./NewsViewer";
import { connect } from "react-redux";
import { changeTab, getNews, publish } from "../../../redux/actions/news/news.actions";

const mapStateToProps = (state)=>{
    return {
        feed: state.news.data,
        totalEntries: state.news.totalEntries,
        loading: state.news.loading,
        next: state.news.next,
        selectedTab: state.news.selectedTab
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        fetchNews: (next,pubId)=>{
            return dispatch(getNews(next,pubId))
        },
        publish: (id, payload)=>{
            return dispatch(publish(id, payload))
        },
        changeTab: (tabId) => {
            return dispatch(changeTab(tabId));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsViewer)