import React, {Component} from "react";
import Container from "../../../components/sidebar/ContentContainer";
import { Add as AddService } from "../../../components/button/buttons";
import styles from "./services.module.css"
import {SUPPORT_SERVICE_TYPE as TYPE} from "../../../utils/constants"
import IMAGES from "../../../assets/images/images"
import { Publish, Edit, Delete, Chevron} from "../../../components/content/Actions"
import {Item, ActionContainer, DetailsContainer, Status} from "../../../components/content/Containers"
import {ContentImage as ServiceImage, DefaultContentImage as DefaultImage} from "../../../components/content/ContentImage"
import {showConfirmation, BUTTON_TYPES} from "../../../components/alert/Confirmation";
import {showError, showSuccess} from "../../../utils/toasts";
import {useNavigate} from "react-router-dom";
import { SectionLoader} from "../../../components/loader/Loaders";
import CAN from '../../../casl/can';
import SectionGaurd from "../../../gaurds/SectionGaurd";
import {Action,Resource} from "../../../casl/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Stats from "../../../components/stats/stats";


export const Title = ({text})=> (<label className={styles.title}>{text}</label>)
export const Subtitle = ({text})=>(<span className={styles.subtitle}>{text}</span>)
const Type = ({type, icon})=>{
   return (
    <div className={styles.type}>
        <img className={styles.typeIcon}  src={icon}/>
        {type}
    </div>
   );
}


const serviceTypeImage = (type)=>{
    let image
    switch(type){
        case TYPE.Advice:
            image = {ph: IMAGES.advice, icon: IMAGES.ic_advice}
            break;
        case TYPE.Hate_Crime:
            image = {ph: IMAGES.hate_crime, icon: IMAGES.ic_hc}
            break;
        case TYPE.Housing:
            image = {ph: IMAGES.housing, icon: IMAGES.ic_house}
            break;
        case TYPE.Mental_Health:
            image = {ph: IMAGES.mental_health, icon: IMAGES.ic_mh}
            break;
        case TYPE.Sexual_Health:
            image = {ph: IMAGES.sexual_health, icon: IMAGES.ic_sh}
            break;
        default:
            image = {ph: IMAGES.other, icon: IMAGES.ic_other}
    }
    return image
}

const Icon = ({url, placeholder })=>{
    if(!url){
        return <DefaultImage image={placeholder}/>
    }
    return (
        <ServiceImage url={url}/>
    );
}

const Service = ({data, onDelete, onPublish,onEdit,serviceTypes})=>{
    const notPublished = data.status !== "published"
    const statusStyle = notPublished ? `${styles.pending}` : ""
    const image = serviceTypeImage(data?.serviceType)
    let _serviceType= serviceTypes && serviceTypes.find(item=>item.value===data?.serviceType);
    return (
        <Item>
            <Icon url={data.serviceIcon} placeholder={image.ph}/>
            <DetailsContainer>
                <Title text={data.serviceName} />
                <Subtitle text={data.description} />
                <Type icon={image.icon} type={_serviceType?.label} />
            </DetailsContainer>
            <Status status={data.status} style={statusStyle}/>
            <ActionContainer>
                <Publish show={notPublished} onPublish={onPublish}/>
                <Edit onClick={onEdit}/>
                <Delete onClick={onDelete}/>
                {/* <Chevron /> */}
            </ActionContainer>
        </Item>
    );
}

const Services = ({data, onDelete, onPublish,onEdit,loading,serviceTypes})=>{
    let navigate = useNavigate();
    return (
      <div className={styles.listContainer}>
        <ul className={styles.serviceList}>
          {
            loading && data.length==0? (
                <SectionLoader />
            ) : (
                data.map((service) => {
                return (
                    <Service
                    data={service}
                    key={service.id}
                    onDelete={() => onDelete(service.id)}
                    onPublish={() => onPublish(service.id)}
                    onEdit={() => {
                        onEdit(service);
                        navigate("/new-service");
                    }}
                    serviceTypes={serviceTypes}
                    />
                );
                })
            )
          }
        </ul>
      </div>
    );
}

export default class ServicesViewer extends Component {

    constructor(props){
        super(props)
        this.deleteService = this.deleteService.bind(this)
        this.publishService = this.publishService.bind(this)
        this.editService = this.editService.bind(this)
    }

    componentDidMount(){
        this.props.getServiceTypes();
        let isLoadMore=false;
        let _next=null
        this.props.getServices(isLoadMore,_next)
    }

    fetchData=(nextPage)=>{
        if (nextPage) {
            let isLoadMore=true;
            this.props.getServices(isLoadMore,nextPage);
        }
    }

    publishService(id){
        const payload = {
            status: "published"
        }
        //The promise is returned so that a loader can be implemented in place of the button
        return new Promise((resolve, reject)=>{
            this.props.publish(payload, id)
            .then((success)=>{
                resolve(true)
                if(!success){
                    showError("Something went wrong")
                }else{
                    showSuccess("Service published successfully")
                    let isLoadMore=false;
                    let _next=null
                    this.props.getServices(isLoadMore,_next)
                }
            })
        })
    }

    deleteService(id){
        const buttons = [
            {
                label: "Delete",
                type: BUTTON_TYPES.Destructive,
                onClick: ()=> {
                    return new Promise((res, rej)=>{
                        this.props.delete(id)
                        .then((success)=>{
                            res(true)
                            if(success){
                                showSuccess("Service deleted successfully")
                                let isLoadMore=false;
                                let _next=null;
                                this.props.getServices(isLoadMore,_next)
                            }else{
                                showError("Something went wrong while deleting the service")
                            }
                        })
                    })
                }
            },
            {
                label: 'Cancel',
                type: BUTTON_TYPES.Cancel,
                onClick: () => {
                    return Promise.resolve(true)
                }
            }
        ]
        const title = "Confirm to delete"
        const message = "Are you sure you want to delete this service?"

        return showConfirmation(title, message , buttons)
    }
    editService(service){
        this.props.edit(service)
    }

    render(){
        const {services,loading,nextPage,serviceTypes} = this.props
        return (
          <Container>
            <SectionGaurd canAccess={CAN(Action.CREATE, Resource.SERVICES)}>
              <div className={styles.divFlex}>
                {loading ? null : (
                  <Stats
                    className={styles.total}
                    label="Total Services"
                    value={this.props.totalEntries}
                  />
                )}
                <AddService label="Add Service" path="/new-service" />
              </div>
            </SectionGaurd>
            <SectionGaurd canAccess={CAN(Action.LIST, Resource.SERVICES)}>
              <InfiniteScroll
                dataLength={services.length} //This is important field to render the next data
                next={() => this.fetchData(nextPage)}
                hasMore={nextPage}
                loader={
                  <Container>
                    <SectionLoader />
                  </Container>
                }
              >
                <Services
                  data={services}
                  loading={loading}
                  onDelete={this.deleteService}
                  onPublish={this.publishService}
                  onEdit={this.editService}
                  serviceTypes={serviceTypes}
                />
              </InfiniteScroll>
            </SectionGaurd>
          </Container>
        );
    }
}