import React, { forwardRef, useEffect, useRef, useState } from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormTextInput } from "../../../../components/input/inputs";
import Dropdown from "../../../userManagement/add/components/DropDown";
import { DATE_FORMAT } from "../../../../utils/constants";
import IMAGES from "../../../../assets/images/images";
import styles from "./Filters.module.css";
import { DateTime } from "luxon";

export const FilterById = () => {
  return (
    <FormTextInput name="incidentId" placeholder="Filter Id should start with INC... " />
  );
};

export const MoreFilters = () => {
  const [reviewStatus, setReviewStatus]= useState(false);

  const handleFilterChange = (isChecked) =>{
    setReviewStatus(isChecked);
  }
  const filterList = [
    <FilterWithRadioOptions
      label="Filter by Need Support"
      name="need_support"
      options={[
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ]}
    />,
    <FilterWithRadioOptions
      label="Filter by Can Contact"
      name="can_contact"
      options={[
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ]}
    />,
    // <FilterWithRadioOptions
    //   label="Filter by Status"
    //   name="review_status"
    //   options={[
    //     { label: "Reviewed", value: "reviewed" },
    //     // { label: "Not Reviewed", value: "notReviewed" },
    //     // { label: "None", value: "none" },
    //   ]}
    // />,
    <FilterWithCheckbox
      label="Reviewed"
      name="review_status"
      onFilterChange={handleFilterChange}
    />,
    <FilterByExpiry />,
    <FilterByCustomDateRange />,
  ];

  return (
    <ul
      className={`${styles.noPadding} ${styles.filterContainer} ${styles.container}`}
      style={{ gap: 12 }}
    >
      {filterList.map((filter) => (
        <li className={styles.filterItem}>{filter}</li>
      ))}
    </ul>
  );
};

export const FilterWithRadioOptions = ({ label, name, options }) => {
  return (
    <div>
      <span className={styles.checkbox__label}>{label}</span>
      <ul className={`${styles.hLine} ${styles.noPadding} ${styles.gap}`}>
        {options.map(({ label, value }) => (
          <li className={`${styles.filterItem} ${styles.hLine}`} key={value}>
            <input
              id={`${name}-${value}-option`}
              type="radio"
              name={name}
              value={value}
            />
            <label htmlFor={`${name}-${value}-option`}>{label}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const FilterWithCheckbox = ({label,name, onFilterChange}) =>{
  const [isChecked, setIsChecked]= useState(false);

  const handleCheckboxChange = (event) =>{
    setIsChecked(event.target.checked);
    onFilterChange(event.target.checked);
  }
  return (
    <div>
      <input
        type="checkbox"
        value="reviewed"
        name={name}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={name} className={styles.review_checkbox__label} >{label}</label>
    </div>
  )
}

export const FilterByExpiry = () => {
  const options = [
    { label: "1 week", value: "7" },
    { label: "2 weeks", value: "14" },
    { label: "30 days", value: "30" },
  ];

  const submitRef = useRef();
  const [selectedValue, setSelectedValue] = useState();
  useEffect(() => {
    if (submitRef.current instanceof HTMLInputElement) {
      submitRef.current.click()
    }
  }, [selectedValue]);

  return (
    <div>
      <label className={styles.checkbox__label}>Filter by Expiry</label>
      <div className={styles.fluidInput}>
        <input type="hidden" name="expiryIn" value={selectedValue?.value} />
        <input
          ref={submitRef}
          type="submit"
          data-ignore="true"
          name="change-trigger"
          tabIndex={-1}
          aria-hidden="true"
          className={styles.srOnly}
        />
        <Dropdown
          value={selectedValue}
          onChange={setSelectedValue}
          options={options}
        />
      </div>
    </div>
  );
};

const FilterByCustomDateRange = () => {
  const submitRef = useRef();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const dateToUnix = (date) => {
    return DateTime.fromJSDate(date).plus({day: 1}).toUnixInteger();
  };

  const [adjustedStartDate, adjustedEndDate] = adjustStartAndEndDate(
    startDate,
    endDate
  );

  useEffect(() => {
    if (submitRef.current instanceof HTMLInputElement) {
      submitRef.current.click()
    }
  }, [startDate, endDate]);

  return (
    <div>
      <label className={styles.checkbox__label}>Filter by Date Range</label>
      <div className={styles.fluidInput}>
        <input
          ref={submitRef}
          type="submit"
          data-ignore="true"
          name="change-trigger"
          tabIndex={-1}
          aria-hidden="true"
          className={styles.srOnly}
        />
        {adjustedStartDate && (
          <input
            type="hidden"
            name="startDate"
            value={dateToUnix(new Date(adjustedStartDate))}
          />
        )}

        {adjustedEndDate && (
          <input
            type="hidden"
            name="endDate"
            value={dateToUnix(new Date(adjustedEndDate))}
          />
        )}

        <div className={styles.radio__container}>
          <label className={styles.checkbox__label}>Start date</label>
          <Datepicker
            placeholderText="start date"
            dateFormat={DATE_FORMAT}
            maxDate={adjustedEndDate || new Date()}
            selected={startDate}                                                                           
            onChange={(date) => {
              setStartDate(date);
            }}
            customInput={<DateInput />}
          />
        </div>
        <div className={styles.radio__container}>
          <label className={styles.checkbox__label}>End date</label>
          <Datepicker
            placeholderText="end date"
            dateFormat={DATE_FORMAT}
            minDate={adjustedStartDate}
            maxDate={new Date()}
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            customInput={<DateInput />}
          />
        </div>
      </div>
    </div>
  );
};

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={styles.dateInputButton}
    onClick={onClick}
    ref={ref}
  >
    <img alt="" className={styles.calendarIcon} src={IMAGES.calendar} />
    {value}
  </button>
));

const adjustStartAndEndDate = (start, end) => {
  if (start > end) {
    return [start, start];
  }

  if (end < start) {
    return [end, end];
  }

  return [start, end];
};
