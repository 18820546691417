import React, {useEffect, Fragment} from "react";
import Container from "../../../components/sidebar/ContentContainer";
import styles from "./archivedIncident.module.css"
import {
    FormFieldLabel as Label,
    FormFieldValue as Value,
} from "../../../components/label/Labels";
import Section from "../../../components/form/Section";
import CAN from "../../../casl/can";
import { Action, Resource } from "../../../casl/constants";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import {useParams, useNavigate } from "react-router-dom";
import {ButtonDestructiveWS} from "../../../components/button/buttons"
import { SectionLoader } from "../../../components/loader/Loaders";
import {showError, showSuccess} from "../../../utils/toasts";
import { Link } from "react-router-dom";
import IMAGES from "../../../assets/images/images";
import { DateTime } from "luxon";

const BackButton = () => {
  return (
    <Link to="/archived-incidents" className={`${styles.submit} ${styles.back}`}>
      <img src={IMAGES.chevron} alt="arrow" className={styles.backButton} />
      Back
    </Link>
  );
};

const FieldWrapper = ({ children }) => {
    return <div className={styles.fieldContainer}>{children}</div>;
  };
  const RadioField = ({ data,index }) => {
    const hasAnswerOther = data.ans === 'other';

    return (
      <FieldWrapper>
        <Label text={`${index}. ${data.que}`}/>
        <Value text={data.ans ? data.ans : " "} />
        {data.extra &&
          data.extra.map((item, index) => {
            return <ExtraQuesField hideLabel={hasAnswerOther && item.label === 'other'} data={item} key={index} />;
          })}
      </FieldWrapper>
    );
  };
  const CheckBoxField = ({ data,index  }) => {
    const hasAnswerOther = data.ans?.some(ans => ans === 'other');

    return (
      <FieldWrapper>
        <Label text={`${index}. ${data.que}`} />
        {data.ans && data.ans.length>0 ?
          data.ans.map((item, index) => {
            return <Value text={item} key={index} />;
          }):' '}
        {data.extra &&
          data.extra.map((item, index) => {
            return (
              <ExtraQuesField
                hideLabel={item.label === "other" && hasAnswerOther}
                data={item}
                key={index}
              />
            );
          })}
      </FieldWrapper>
    );
  };
  const DateField = ({ data,index  }) => {
    return (
      <FieldWrapper>
        <Label text={`${index}. ${data.que}`} />
        <Value text={data.ans ? DateTime.fromISO(data.ans).toFormat("dd LLL yyyy") : " "}  />
        {data.extra &&
          data.extra.map((item, index) => {
            return <ExtraQuesField data={item} key={index} />;
          })}
      </FieldWrapper>
    );
  };
  const LocationField = ({ data,index  }) => {
    return (
      <FieldWrapper>
        <Label text={`${index}. ${data.que}`} />
        {data.ans && data.ans.length>0 ?
          data.ans.map((item, key) => {
            return <KeyValueField label={item.label} value={item.value} />;
          }):'  '}
        {data.extra &&
          data.extra.map((item, index) => {
            return <ExtraQuesField data={item} key={index} />;
          })}
      </FieldWrapper>
    );
  };
  const KeyValueField = ({ label, value }) => {
    return (
      <div className={styles.KeyValuePairField}>
        <SecondaryLabel label={`${label} ${":"}`} />
        <Value text={value} />
      </div>
    );
  };
  const DescriptionField = ({ data,index  }) => {
    return (
      <FieldWrapper>
        <Label text={`${index}. ${data.que}`} />
        <Value text={data.ans ? data.ans : " "} />
        {data.extra &&
          data.extra.map((item, index) => {
            return <ExtraQuesField data={item} key={index} />;
          })}
      </FieldWrapper>
    );
  };
  const ExtraQuesField = ({ data, hideLabel = false }) => {
    return (
      <React.Fragment>
        {!hideLabel ? <SecondaryLabel label={data.label} /> : null}
        <Value text={data.value} />
      </React.Fragment>
    );
  };
  const ContactField = ({ data,index  }) => {
    return (
      <FieldWrapper>
        <Label text={`${index}. ${data.que}`} />
        {data.ans && data.ans.length>0 ?
          data.ans.map((item, key) => {
            return <KeyValueField label={item.label} value={item.value} />;
          }):' '}
        {data.extra &&
          data.extra.map((item, index) => {
            return <ExtraQuesField data={item} key={index} />;
          })}
      </FieldWrapper>
    );
  };
  
  const SecondaryLabel = ({ label }) => {
    return <label className={styles.secondaryLabel}>{label}</label>;
  };
  const AboutIncident = ({ data, ...props }) => {
    return data.map((item, index) => {
      return <QuestionContainer item={item} number={index+1} key={index}/>;
    });
  };
  const AboutYou = ({ data, ...props }) => {
    return data.map((item, index) => {
      return <QuestionContainer item={item} number={index+1} key={index}/>;
    });
  };
  const AboutSupport = ({ data, ...props }) => {
    return data.map((item, index) => {
      return <QuestionContainer item={item} number={index+1} key={index}/>;
    });
  };
  
  const QuestionContainer = ({ item,number}) => {
    return item.type === "radio" ? (
      <RadioField data={item} index={number}/>
    ) : item.type === "date" ? (
      <DateField data={item} index={number}/>
    ) : item.type === "location" ? (
      <LocationField data={item} index={number}/>
    ) : item.type === "checkbox" ? (
      <CheckBoxField data={item} index={number}/>
    ) : item.type === "description" ? (
      <DescriptionField data={item} index={number}/>
    ) : item.type === "contact" ? (
      <ContactField data={item} index={number}/>
    ) : (
      <></>
    );
  };

const Details = ({incident, unarchive, loading, id})=>{
  if(loading){
    return (<SectionLoader />);
  }
  return (
    
      <Fragment>
        <Section title={"About the incident"}>
         <div className={styles.body}><AboutIncident data={incident ? incident["incident"] : []} /></div>
        </Section>
        <Section title={"About you"}>
            <div className={styles.body}><AboutYou data={incident ? incident["about_you"] : []} /></div>
        </Section>
        <Section title={"Receiving support"}>
           <div className={styles.body}> <AboutSupport data={incident ? incident["support"] : []} /></div>
        </Section>

        <ButtonDestructiveWS onClick={unarchive}>Unarchive</ButtonDestructiveWS>
      </Fragment>
    
  );
}

const ArchivedIncident = (props)=>{

    const {incident, unarchive, loading} = props
    const { id } = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        if(CAN(Action.GET, Resource.INCIDENT)){
          props.get(id);
        }
    }, []);

    const unarchiveIncident = ()=>{
      return new Promise((resolve, reject)=>{
          unarchive(id)
          .then(success => {
            showSuccess(`Incident ${incident.incident_id || id} unarchived successfully`)
            resolve()
            navigate("/archived-incidents", {replace: true}) //go back to archievd incidents list
          })
          .catch(error => {
            showError(`An error occurred while unarchiving incident ${incident.incident_id || id}`)
            reject()
          })
      })
    }

    return (
        <Container>
            <SectionGaurd canAccess={CAN(Action.GET, Resource.INCIDENT)}>
            <BackButton />
              <div className={styles.container}>
                  <h1 className={styles.pageTitle}>Incident : {incident?.incident_id || incident?.id}</h1>
                  <Details incident={incident} unarchive={unarchiveIncident} loading={loading} id={id}/>
                </div>
            </SectionGaurd>
        </Container>
    );
}

export default ArchivedIncident